import React, { useMemo, useContext }from "react";
import { AppContext } from '../../context';

const PDFPreview = ({ data }) => {
  const { 
    userContext,
  } = useContext(
    AppContext
  );

  const previewUrl = useMemo(
    () => {
      if (data) {
        return data.replace("__PUBLIC_TOKEN__", userContext.search_params);
      }
    }, [data]
  );

  return (

    <iframe className="pdf-preview" type="application/pdf" src={`${previewUrl}#toolbar=0`} 
    width="100" height="141" ></iframe>   

  );
};

export default PDFPreview;