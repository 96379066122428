import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Container } from 'react-bootstrap';
import { getUser, removeUserSession, removeUserStats } from '../../../functions/manageSessions';
import {ReactComponent as MedwiseLogoWithName} from '../../../assets/svgs/medwise-logo-with-name.svg';

// import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';

import listOfProfessions from '../listOfProfessions.json';
import listOfSpecialities from '../listOfSpecialities.json';
import Button from '../../button';
import { H1, Span, Label } from '../../theme/typography';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import LocationSuggest from '../../location-autosuggest';
import { AppContext } from '../../../context';
import SignUpStepTwo from './step-two-signup/index';
import SignUpStepEmail from './step-email-signup/index';
import {
  SignUpFormTitleWrapper, // for tittles
  Breadcrumb,
  FormFieldWrapper,
  TextInput,
  ErrorMessage,
  
} from '../styles';
import SignUpStepAddSources from './step-add-sources/index';
import GoogleSuggest from '../../google-map-suggest';
import Select, { components } from 'react-select';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen';


const SignUpFlow = (props) => {
  const isSmallScreen =  useIsSmallScreen();
  const [suggestedSources, setSuggestedSources] = useState(undefined);
  const [otherProfessionSelected, setOtherProfessionSelected] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState('');
  const [profession, setProfession] = useState('');
  const [professionError, setProfessionError] = useState(false);
  // const [professionOther, setProfessionOther] = useState('');
  const [professionOtherError, setProfessionOtherError] = useState(false);
  const [step, setStep] = useState(1);
  const [signUpEmailError, setSignUpEmailError] = useState(undefined);
  const [loginCodeError, setLoginCodeError] = useState(undefined);
  const [loginCodeErrorOrg, setLoginCodeErrorOrg] = useState(undefined);
  const [inputEmail, setInputEmail] = useState("");
  const [setUpAccount, setSetupAccount] = useState("");
  const [location, setLocation] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [locationInputChange, setLocationInputChange] = useState(false);
  const [errorPracticeOther, setErrorPracticeOther] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [odsCode, setOdsCode] = useState(undefined);
  // const [odsCodeError, setOdsCodeError] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [registrationNumberError, setRegistrationNumberError] = useState(false);


  const {setRequestTrackerContext, setUserContext, userContext,removeUserOrganisationContext} = useContext(AppContext);
  const [isLinked, setIsLinked] = useState(false);
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const [notLoading, setNotLoading] = useState(true);
  const [loadingIcb, setLoadingIcb] = useState(false);
  const [organisation, setOrganisation] = useState(undefined);
  const [organisationSources, setOrganisationSources] = useState([]);
  const [consent, setConsent] = useState(false);
  const [icbName, setIcbName] = useState(undefined);
  const [blockNext, setBlockNext] = useState(true);
  const [place, setPlace] = useState(undefined);
  const [userVersion, setUserVersion] = useState(undefined);
  const [userIcb, setUserIcb] = useState(undefined);
  const [versions, setVersions] = useState(undefined);
  const [defaultVersion, setDefaultVersion] = useState(undefined);
  const [defaultIcb, setDefaultIcb] = useState(undefined);
  const [icbs, setIcbs] = useState(undefined);
  const [blockGetVersion,setBlockGetVersion]= useState(true); // change only by child google suggest
  const [resetIcb,setResetIcb]= useState(false);


  const history = useHistory();

  const userLocationContext = useMemo(() => {
    let userInfo = userContext;
    if (userInfo && userInfo.location && props.defaultStep === 3) {
      setLocationOther(userInfo.location);
      return userInfo.location;
    };
    return '';
  }, [userContext]);

  const otherLocationSelectedUserContext = useMemo(() => {
    let userInfo = userContext;
    if (userInfo && userInfo['location'] && userInfo['ods_code'] && userInfo['postcode']) {
      return false;
    }
    else {
      if (userInfo && userInfo['location'] && typeof userInfo['location'] === "string" && userInfo['location']!== '' && props.defaultStep === 3) {
        return true;
      }
    }
  }, [userContext]);

  const userProfessionContext = useMemo(()=>{
    let userInfo=userContext;
    if (userInfo && userInfo.profession){
      if( listOfProfessions.includes(userInfo.profession.trim())){
        return userInfo.profession;
      }
    }
    return '';
  },[userContext]);
  
  const otherProfessionSelectedUserContext = useMemo(()=>{
    let userInfo=userContext;
    if (userInfo && userInfo.profession && userInfo.profession.trim() !== '' ){
      if( listOfProfessions.includes(userInfo.profession.trim())){
      return false;
      }
      else{
          return true;
      }
    }
    return false;
    },[userContext]);


  const setLocationFromChild = (i)=>{
    setSuggestedSources(undefined);
    setIcbName(undefined);
    setLocation(i);
    if (i !== 'Other') {
      setTempLocation(i);
    };
    setLocationOther('');
    setLocationInputChange(true);
    setLocationError(false);
    setErrorPracticeOther(false);
  };

  useEffect(() => {
    if (location === 'Other')
      setLocationOther(tempLocation);
  }, [location]);


  useEffect(() => {
   if(resetIcb){
    setBlockNext(true);
    if( typeof place !== 'undefined' && (!blockGetVersion ||( userContext['location'] && userContext['location']!== '' ) ) ){
      get_medwise_version({'place':place},undefined);
    }
    else{
      if((location!== '' && typeof place === 'undefined' )&& (!blockGetVersion ||( userContext['location'] && userContext['location']!== '' ) ) ){
        get_medwise_version({'place':{'formatted_address': location}},undefined);
      }
    }
    setResetIcb(false);
   }
  }, [resetIcb]);

  const setPostalCodeFromChild = (i)=>{
    setPostalCode(i);
  };

  const setOdsCodeFromChild = (i)=>{
    setOdsCode(i);
    setLocationError(false);
  };

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        (promiseInProgress ) &&
        <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
       }}
       >
        <Loader type="Oval" color="#8771DF" height="100" width="100" />
    </div>
      );
  };

  // Use effect 
  useEffect(() => {
    if (props.defaultStep === 3 && step <= 3) {
      setStep(3);
      let user_info = getUser();
      if (user_info && user_info['org_name']){
        setIsLinked(true);
        setOrganisation(user_info['org_name']);
      };
      if (user_info && user_info['first-names']) {
        setFirstName(user_info['first-names']);
      };
      if (user_info && user_info['last-names']){
        setLastName(user_info['last-names']);
      };

      if (user_info && user_info['profession'] && user_info['profession'].trim() !== '') {
        setProfession(user_info['profession'].trim());
        // list of professiond includes any 'preloaded' profession 
        // so we assume other is not selected
        setSelectedProfession(user_info['profession']);
      };
      if (user_info && user_info['location'] !== '' ){
        setLocation(user_info['location']);
        get_signup_options_preloaded({'place':user_info['location']},user_info,undefined);
      };

    };
  }, [props.defaultStep, step]);

  useEffect(() => {
    if (userContext && !userContext['is_anonymous'] && userContext["email"]) {
      if (userContext['org_name']) {
        setOrganisation(userContext['org_name']);
      };
    };
  }, [userContext.email]);

  // For email login 
  useEffect(() => {
    let url_email ; 
    let url_code; 
    if(typeof history === 'object' && typeof history.location !== 'undefined' && typeof  history.location.search === 'string'){
      url_email = history.location.search.split('?email=');
      if(url_email.length >1 ){
        url_code =  url_email[1].split('&code=');
        if(url_code.length > 1 ){
          url_email = decodeURIComponent(url_code[0]);
          url_code = url_code[1];
        }
      }
      if( typeof url_code === 'string' && typeof url_email ==='string' )
      {
        let data={ "email": url_email , "code": url_code}
        login_user(data, 'login-user');
      }
    }

  },[history]);


  // trigger get medwise version
  // blockGetVersion default is true and is only change by child component GoogleSuggest
  useEffect(() => {
    setUserVersion(undefined);
    if( typeof place !== 'undefined' && !blockGetVersion ){
      get_medwise_version({'place':place},undefined);
    }
    else{
      if((location!== '' && typeof place === 'undefined' )&& !blockGetVersion ){
        get_medwise_version({'place':{'formatted_address': location}},undefined);
      }
    }
  }, [location,place,blockGetVersion]); 



  const signupSchema3 = yup.object().shape({
    // first_name: yup.string().required('Please enter your first name'),
    // last_name: yup.string().required('Please enter your last name'),
    // profession: yup.string().required("Please select your profession"),
    // profession: yup.string().test({
    //   test(value, ctx) {
    //     if (value.startsWith('Select your profession')) {
    //       return ctx.createError({ message: 'Please select your profession' })
    //     }
    //     return true
    //   }
    // }),
    // profession_other: yup.string().when('profession', {
    //   is: (profession)=> profession==="Other (type below)",
    //   then: yup.string().required("Please select your profession")
    // }),
  },['profession','profession_other']);


  // this method send the post request to login with code
  const login_user = (data,endpoint) => {
    let data_to_send = JSON.stringify({
      "email": data.email,
      "code": data.code
    });
    setNotLoading(false);
    setRequestTrackerContext(prev => { return {...prev, endpoint:true}});
    let endpoint_url = window.BASE_URL_USER + endpoint;
    trackPromise(
      axios({
        method: 'post',
        url: endpoint_url,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data_to_send,
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, endpoint:false}});
          setNotLoading(true);
          if (res.data.success) {
            removeUserOrganisationContext();
            if (!res.data.user.is_anonymous){
              if(setUpAccount){
                setStep(3);
                setLoginCodeError(undefined);
                setLoginCodeErrorOrg(undefined)
                removeUserStats();
                //TO DO also get filters
                // setUserSession(res.data.user);
                setUserContext(res.data.user);
                // setOrganisationSources(res.data.user['custom-sources']);
                setOrganisationSources(undefined);
                autofocusFirstName();
              }else{
                if(res.data.user){
                  // setUserSession(res.data.user);
                  setUserContext(res.data.user);
                  resetUser();
                  // if (res.data.user.org_name) {
                  //   setOrganisation(res.data.user.org_name);
                  // };
                }
                window.location =  window.BASE_API_URL;
              }
            }
            else {
              setStep(2);
              setLoginCodeError("Wrong code or email");
            }
          }
          else {
            setStep(2);
            setLoginCodeError("Wrong code or email");
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, endpoint:false}});
          setNotLoading(true);
          setStep(2);
          console.log('Error: ', error);
        })
    );
  };

  //sends a login request if error === Could not match email, please try again or subscribe.
  const requestCode = (data) => {
    setRequestTrackerContext(prev => { return {...prev, 'send-email-with-login-code':true}});
    setNotLoading(false);
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}send-email-with-login-code`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          email: data.email,
        }),
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'send-email-with-login-code':false}});
          setNotLoading(true);
          if (res.data.success) {
            setSetupAccount(false);
            setConsent(true);
            props.setUserEmail( data.email);
            setSignUpEmailError(undefined);
            setInputEmail(data.email);
            setIsLinked(false);
            props.setRemoveAgreement();   
            setStep(2);        
          }
          else if (res.data.redirect) {
            window.location.replace(res.data.redirect_url + "&username=" + res.data.email);
          } else {
            // props.setUserEmail(res.data.email);
            // go to signup ....
            setSetupAccount(true);
            registers_user_send_email(data);          
          }
        })
        .catch((error) => {
          // setSentCodeError(undefined);
          // setIsLoading(false);
          setRequestTrackerContext(prev => { return {...prev, 'send-email-with-login-code':false}});
          // props.setUserEmail(email);
          // setSignUpEmailError(undefined);
          // setInputEmail(data.email);           
          console.log('Error: ', error);
        })
    );
};

  // This  method send the post request that registers the user
  const registers_user_send_email = (data) => {
    var data_to_send = {
      email:data.email,
    };
    if(data['consentLinking']===false ||data['consentLinking']===true)
    {  
      data_to_send = {
        ...data_to_send, 
        'link-org': data['consentLinking'].toString()
      };
    }
    setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':true}});
    setNotLoading(false);
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}subscribe-user`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data_to_send),
      })
        .then((res) => {
          // setIsWaitingForRequest(false);
          // setNotLoading(true);
          setConsent(true);
          setInputEmail(data.email);
          if (res.data.success) {
            setNotLoading(true);
            let email = data.email;
            props.setUserEmail(email);
            setSignUpEmailError(undefined);
            setInputEmail(data.email);           
            if(data['consentLinking']){
              setIsLinked(data['consentLinking']);
            }
            else{
              setIsLinked(false);
              // setOrganisation(undefined);
            }
            props.setRemoveAgreement();
            setStep(2);
          }
          else {
            if (res.data.redirect) {
              window.location.replace(res.data.redirect_url + "&username=" + data.email);
            } else {
              setNotLoading(true);
              if(res.data.info && res.data.info['must-be-linked'] ){
                setIsLinked(res.data.info['must-be-linked']);
                setOrganisation(res.data.info['organisation']);
                step1CallBack(data['email'], data["consent"], true);
              }
              if (res.data.msg){
                props.setUserEmail(data.email);
                setSignUpEmailError(false);
                if ( !res.data.info['must-be-linked'] ){
                  setSignUpEmailError(res.data.msg);
                }
              }
              else {
                if(res.data.description){
                  setSignUpEmailError("Server error");
                }
              }
            }
          }
          setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':false}});
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':false}});
          setNotLoading(true);
          setStep(1);
          console.log('Error: ', error);
        })
    );
  };

  // submit for name and profession with prealoaded values
  // we use the usercontext values for user instead of hooks
  // bc hook have not been initialized yet 
  const get_signup_options_preloaded = (data,user,ods_code) => {
    
    // setNotLoading(false);
    let temp_odsCode  ; 
    if (typeof ods_code !== 'undefined'){
      temp_odsCode = ods_code['ods_code'];
    }else{
      temp_odsCode = undefined;
    }
    setLoadingIcb(true);
    setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':true}});
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}suggest-signup-options`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "first_name": user['first-names'],
          "last_name": user['last-names'],
          "email": user.email , 
          "profession":user.profession,
          "location_object": data.place,
          'ods_code':temp_odsCode,
        }),
      })
        .then((res) => {
          // setNotLoading(true);
          setLoadingIcb(false);
          setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
          if (res.data) {
            // check that we have a default and that we did not input ods_code
            if(res.data.default_icb && typeof ods_code === "undefined"){
              setDefaultIcb (res.data.default_icb); 
              setUserIcb(undefined);
            }
            else{
              if( typeof ods_code !== "undefined" ){
                setDefaultIcb (ods_code); // if we input an odscode we had selected icb
              }
              else{
                setUserIcb(undefined);
                setDefaultIcb (undefined); 
              }
            }
            setDefaultVersion(res.data.default_version); 
            setUserVersion(undefined);
            if( res.data.default_version){
              setBlockNext(false);
            }
            setVersions(res.data.suggested_versions);
            setIcbs(res.data.suggested_icbs);
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
          setLoadingIcb(false);
          // setNotLoading(true);
          console.log('Error: ', error);
          setStep(3);
        })
    );
  };

  // submit for name and profession
  const get_medwise_version = (data,ods_code) => {
    const stepThreeValues = {
      first_name: firstName,
      last_name: lastName,
      profession_other: profession,
      practice_other: locationOther,
      registration_number: registrationNumber,
    };
    if (selectedProfession === "Other") {
      stepThreeValues['profession'] = stepThreeValues['profession_other'];
    } else {
      stepThreeValues['profession'] = selectedProfession;
      stepThreeValues['profession_other'] = 'something';
    }
    stepThreeValues['email'] = inputEmail !== "" ? inputEmail : userContext['email'];
    // setNotLoading(false);
    let temp_odsCode  ; 
    if (typeof ods_code !== 'undefined'){
      temp_odsCode = ods_code['ods_code'];
    }else{
      temp_odsCode = undefined;
    }
    setLoadingIcb(true);
    setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':true}});
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}suggest-signup-options`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "first_name": stepThreeValues.first_name,
          "last_name": stepThreeValues.last_name,
          "email": stepThreeValues.email , 
          "profession":stepThreeValues.profession,
          "location_object": data.place,
          'ods_code':temp_odsCode,
        }),
      })
        .then((res) => {
          // setNotLoading(true);
          setLoadingIcb(false);
          setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
          if (res.data) {
            // check that we have a default and that we did not input ods_code
            if(res.data.default_icb && typeof ods_code === "undefined"){
              setDefaultIcb (res.data.default_icb); 
              setUserIcb(undefined);
            }
            else{
              if( typeof ods_code !== "undefined" ){
                setDefaultIcb (ods_code); // if we input an odscode we had selected icb
              }
              else{
                setUserIcb(undefined);
                setDefaultIcb (undefined); 
              }
            }
            setDefaultVersion(res.data.default_version); 
            setUserVersion(undefined);
            if( res.data.default_version){
              setBlockNext(false);
            }
            setVersions(res.data.suggested_versions);
            setIcbs(res.data.suggested_icbs);
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
          setLoadingIcb(false);
          // setNotLoading(true);
          console.log('Error: ', error);
          setStep(3);
        })
    );
  };

  const getPostalCode = () => {
    if(place && place['address_components']){
      place['address_components'].forEach(component => {
        if(component && component["types"] && component["types"]["postal_code"] ){
          return component["types"]["postal_code"];
        }
      });
    }
    return undefined;
  };

  // submit for name and profession
  const modify_user = (data) => {
    setNotLoading(false);
    setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
    let tempPostalCode = getPostalCode();
    let icbTemp = {'name':undefined , 'ods':undefined}; 
    if(userIcb){
      icbTemp = userIcb;
    }
    else{
      if(defaultIcb){
      icbTemp = defaultIcb;}
    }
    let versionTemp = {'name':undefined,'id':undefined};
    if(typeof userVersion !== "undefined"){
      versionTemp = userVersion;
    }
    else{
      if(typeof defaultVersion !== "undefined"){
      versionTemp = defaultVersion;}
    }
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "first-names": data.first_name,
          "last-names": data.last_name,
          "location": data.location,
          "profession": data.profession, 
          "ods_code":icbTemp['ods_code'],
          // "icb":icbTemp,
          "postcode": tempPostalCode,
          "registration_number": data.registration_number,
          "location_object": place,
          // "org-number":place['org-number'],
          "org_num":versionTemp['id']

        }),
      })
        .then((res) => {
          setNotLoading(true);
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          if (res.data.num_modifications) {
            //success with email address
            setSignUpEmailError(undefined);
            var user_info = getUser();
            // setUserSession({
            //   ... user_info,
            //  "first-names": data.first_name,
            //  "last-names": data.last_name,
            //  "location": data.location,
            //  "ask_for_details":false,
            // });
            setUserContext({
              ... user_info,
             "first-names": data.first_name,
             "last-names": data.last_name,
             "location": data.location,
             "ask_for_details":false,
            });
            removeUserOrganisationContext();
            window.location =  window.BASE_API_URL;
              // window.location will reload the page 
              // history.push('/');
          } 
          else {
            console.log(
              'The request is successful, but for some reason res.data.success is not received.'
            );
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setNotLoading(true);
          console.log('Error: ', error);
          setStep(3);
        })
    );
  };

  // Add Other text input when Other is selected
  const updateProfession = (e) => {
    setProfessionError(false);
    setProfessionOtherError(false);
    setRegistrationNumber('');
    let new_val = e.value;
    if (new_val == "") {
      setOtherProfessionSelected(true);
    } else {
      setOtherProfessionSelected(false);
    };
    if( location !== '' || typeof place !== 'undefined'){
      setResetIcb(true);
    }
    setSelectedProfession(new_val);
  };

  const updateIcb = (e) => {
    let odsCode = e.value;
      if (odsCode !== "") {
        //place can only be undefined or have an object 
        if(place){
          get_medwise_version({'place':place},{'ods_code':e.value,'icb_name': e.name});}
      } 
  };

  const updateVersion = (e) => {
    setUserVersion({'name':e['name'],'id':e['value']});
  };

    
  const updateProfessionOther = (e) => {
    setProfessionOtherError(false);
    let new_val = e.target.value;
    setProfession(new_val);
    if( location !== '' || typeof place !== 'undefined'){
      setResetIcb(true);
    }
  };

  const handleStepThreeNavigation = () => {
    if (step === 3 || props.defaultStep === 3) {
      setFirstNameError(false);
      setLastNameError(false);
      setProfessionError(false);
      setProfessionOtherError(false);
      setLocationError(false);
      setErrorPracticeOther(false);
      const stepThreeValues = {
        first_name: firstName,
        last_name: lastName,
        profession_other: profession,
        practice_other: locationOther,
        registration_number: registrationNumber,
      };
      stepThreeValues['location'] = location;
      stepThreeValues['ods_code'] = odsCode;
      stepThreeValues['postalcode'] = postalCode;
      if (selectedProfession === "Other") {
        stepThreeValues['profession'] = stepThreeValues['profession_other'];
      } else {
        stepThreeValues['profession'] = selectedProfession;
        stepThreeValues['profession_other'] = 'something';
      };

      let errorLocation = false;
      if (location === "Other") {
        stepThreeValues['location'] = stepThreeValues['practice_other'];
      } else {
        stepThreeValues['practice_other'] = 'something';
      };
      // Checks for every field value in step 1. Returns true if all of them are populated
      const checkObjValues = !Object.values(stepThreeValues).some(
        (x) => x === false || x === ''
      );
      if (!stepThreeValues['first_name'] ||
          !stepThreeValues['last_name'] ||
          !stepThreeValues['profession'] ||
          (selectedProfession === "Other" && !stepThreeValues['profession_other']) ||
          (stepThreeValues['location'] === stepThreeValues['practice_other'] && stepThreeValues['practice_other'] == '') || 
          ((selectedProfession === "GP/GP Partner" || selectedProfession === "Locum GP") && ( typeof registrationNumber === 'string' && registrationNumber.trim() === ''))
          ) {
        if((selectedProfession === "GP/GP Partner" || selectedProfession === "Locum GP") && ( typeof registrationNumber === 'string' && registrationNumber.trim() === '')){
          setRegistrationNumberError(true);
        }
        if (!stepThreeValues['first_name']) {
          setFirstNameError(true);
        };
        if (!stepThreeValues['last_name']) {
          setLastNameError(true);
        };
        if (!stepThreeValues['profession']) {
          setProfessionError(true);
        };
        if (selectedProfession === "Other" && !stepThreeValues['profession_other']) {
          setProfessionError(false);
          setProfessionOtherError(true);
        };
  
        // if (stepThreeValues['location'] === stepThreeValues['practice_other'] && stepThreeValues['practice_other'] == '') {
        //   setErrorPracticeOther(true);
        //   setLocationError(false);
        // };
      // } else if ((checkObjValues || isLinked) && !errorLocation) {
      } else if (!errorLocation) {
        modify_user(stepThreeValues);
      };
    };
  };

  const addCustomSources = (checkSuggestions) => {
    var sourcesToAdd= checkSuggestions;
    var data_to_send ={}; 
    let sources_to_send = sourcesToAdd;
    if (sources_to_send.length > 0 ){
      sources_to_send = sources_to_send.map(obj=> ({ ...obj, 'is_default': true }))
      // sources_to_send = sourcesToAdd;
      data_to_send = {
        "source": sources_to_send,
        "create_filters":true,
      };
    }
    else{
      if(step===5 || step === 4 ){
        window.location =  window.BASE_API_URL;
      }
    }
    if (sources_to_send.length>=0)
    {
      setRequestTrackerContext(prev => { return {...prev, 'add-custom-source':true}});
      trackPromise(
        axios({
          method: 'post',
          url:`${window.BASE_URL_USER}add-custom-source`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data_to_send),
        })
          .then((res) => {
            setRequestTrackerContext(prev => { return {...prev, 'add-custom-source':false}});
            if(step===4){
              setStep(5);
              resetUser();
            }
            else{
              if(step===5){
                resetUser();
              }
            }
          })
          .catch((error) => {
            setRequestTrackerContext(prev => { return {...prev, 'add-custom-source':false}});
            setNotLoading(true);
            console.log('Error: ', error);
            setStep(4);
          })
      );
    }
    else{
      if(step === 4){
        window.location =  window.BASE_API_URL;
        // setStep(5);
      }
    }


  };

  const resetUser  = () => {
    setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':true}});
    axios({
      method: 'get',
      url:`${window.BASE_URL_USER}get-or-create-user`,
      withCredentials: true,
    })
      .then((res) => {
        setIsLoadingDefault(false);
        setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':false}});
        const user_info = res.data.user;
        if (typeof user_info !=="undefined" ){
          // setUserSession(user_info);
          setUserContext(user_info);
        }
        if(step ===5 || step===4 ){
          window.location =  window.BASE_API_URL;
          // resetUser();
        }
        else{
          if(step===4){
            setStep(5);
          }
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':false}});
        setNotLoading(true);
      });
  }

  const getSuggestedSources = (code) => {
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      url:`${window.BASE_URL_USER}guideline_suggest`,    
      data: JSON.stringify({
        "ods_code": code,
      }), 
      withCredentials: true,
    })
      .then((res) => {
        if (res.data ){
          if (res.data["icb_name"] && res.data['urls']) {
            //set...
            setIcbName(res.data["icb_name"]);
            setSuggestedSources(res.data['urls']);
            setStep(4);
          }
          else{
            setIcbName(undefined);
            setSuggestedSources(undefined);
            if(!organisation)
              {
                // setStep(5);
                history.push('/');
              }
          }
        }

      })
      .catch((error) => {
        setIcbName(undefined);
        setSuggestedSources(undefined);
        if(!organisation)
          {
            // setStep(5);
            history.push('/');
          }
          // newSuggestions = [{}];
      });

  };

  const step2CallBack = (code) => {
    const stepTwoValues = {};
    stepTwoValues['code'] = code;
    stepTwoValues['email'] = inputEmail;
    stepTwoValues['code'] = stepTwoValues['code'].replaceAll(' ', '');
    login_user(stepTwoValues,'login-user');
  };

  const step1CallBack = (email, consent, consentTwo) => {
    const stepOneValues = {};
    stepOneValues['email'] = email;
    stepOneValues['consent'] = consent;
    stepOneValues['consentLinking'] = consentTwo;
    setInputEmail(email);
    requestCode(stepOneValues);
  };

  const autofocusFirstName = () => {
    const code_input = document.getElementById("first_name_input").focus();
  };

  const updateFirstName = (e) => {
    setFirstNameError(false);
    setFirstName(e.target.value);

    if( location !== '' || typeof place !== 'undefined'){
      setResetIcb(true);
    }
  };

  const updateLastName = (e) => {
    setLastNameError(false);
    setLastName(e.target.value);
    if( location !== '' || typeof place !== 'undefined'){
      setResetIcb(true);
    }
  };

  const updateLocationOther = (e) => {
    setRegistrationNumberError(false);
    setErrorPracticeOther(false);
    setLocationOther(e.target.value);
    setResetIcb(true);
  };

  const updateRegistrationNumber = (e) => {
    if(e.target.value !== ''  ){
      setRegistrationNumberError(false);
    }

    if( location !== '' || typeof place !== 'undefined'){
      setResetIcb(true);
    }
    setRegistrationNumber(e.target.value);
  };

  const getListOfProfessions = ()=>{
    let list = listOfProfessions.map((x) => x);
    if(userContext && userContext['ask_for_details'] && typeof userContext['profession'] === 'string'  && userContext['profession'].trim()  !== '' ){
      let num  = list.push({ "name": userContext['profession'],"label":userContext['profession'], "value":userContext['profession'] });
    } 
    return list; 
  }; 

  // this function returns the name of the professional number
  // if no professional number is found it returns a undefined
  const dict_of_professional_num = {
     "GP/GP Partner" :"GMC number", //  Professional Registration Number
     "Locum GP" :"GMC number",
     "Physician Associate" : undefined,
     "Nurse" : "NMC / PIN number" ,
     "GP Trainee": undefined,
     "Advanced Nurse Practitioner": "NMC / PIN number",
     "Pharmacist": "GPhC / PSNI number",
     "Other": undefined
    };



  return (
    <Container>
      
      <SignUpFormTitleWrapper >
        {! isSmallScreen &&( 
        <MedwiseLogoWithName style={{'width':'175px', 'height':'36px'}}></MedwiseLogoWithName>)}
        <H1 h30 semibold textoffblack style={{'margin': '18px'}}>
          { ((step === 1 )) && 'Log in or Register'}
          { ((step === 2)) && 'Check your email for a code'}
          { ((step === 3 ) && step !== 4) && 'Tell us about yourself'}
          { ((step === 4)) && 'Step up to date with local best pratice.'}
          { ((step === 5)) && 'Add further sources'}
        </H1>
        <Span p1 regular textmainbody>
          { (step === 1 && notLoading ) && ' '}
          {/* { (step === 1 && notLoading ) && ' '} */}
          {/* { (step === 2 ) && 'Enter the code'}
          { ((step === 4)) && ' '}
          { ((step === 5)) && ' '} */}
        </Span>
      </SignUpFormTitleWrapper >
      <Container id='signupFlowContainer'>
          {(step === 1 ) && notLoading  && props.defaultStep!==3 &&(  
            <>
            <SignUpStepEmail 
              isSignup={props.isSignup}
              loginCallBack={step1CallBack}  
              registerError={signUpEmailError } 
              setStepOneConsent ={setConsent} 
              stepOneConsent= {consent} 
              linked={isLinked} 
              email={inputEmail} 
              organisation={organisation}
            />
            </>
          )}
        {!notLoading && (<LoadingIndicator/>)}
        {notLoading && step==2 && props.defaultStep!==3 &&(
            <SignUpStepTwo 
            loginCallBack={step2CallBack} 
            loginError={loginCodeError} 
            loginCodeErrorOrg={loginCodeErrorOrg} 
            email= {inputEmail} />
            )}
        {/* <form onSubmit={handleStepThreeNavigation} id="info_submitted" style={{ display: `${ ((step === 3 || props.defaultStep === 3 ) && step!==4 && step!==5) ? 'block' : 'none'}` }}> */}
        <form id="info_submitted" style={{ display: `${ ((step === 3 || props.defaultStep === 3 ) && step!==4 && step!==5) ? 'block' : 'none'}` }}>

          {/* part of form */}
          <div style={{ display: `${ (step === 3 || props.defaultStep===3 ) ? 'block' : 'none'}` }}>
            {}
            <Row>
              <Col md={12}>
              <FormFieldWrapper>
                  <Label c1 semibold textoffblack>
                    Email
                  </Label>
                  <TextInput
                    disabledGrey={true}
                    disabled="disabled"
                    type="text"
                    name="first_name"
                    id ={"first_name_input"+inputEmail+'_usercontext:_'+userContext['email']}
                    key = {"first_name_input"+inputEmail+'_usercontext:_'+userContext['email']}
                    // ref={register3}
                    // placeholder="First Name"
                    defaultValue={typeof inputEmail === "string" && inputEmail !== "" ? inputEmail : userContext['email']}
                    // onChange={(e) => updateFirstName(e)}                    
                    />
                </FormFieldWrapper>
              </Col>
            </Row>
          {/* name */}
            <Row>
              <Col md={12} >
              {/* <H1 h1 semibold textoffblack style={{ display: `${isLinked? 'block' : 'none'}`, padding: '0 15px 20px 0px' }}> */}
              <H1 h1 semibold textoffblack style={{padding: '16px 15px 20px 0px'}}>
                {organisation && organisation}
              </H1>
              </Col>
              <Col md={6}>
                <FormFieldWrapper>
                  <Label c1 semibold textoffblack>
                    First Name(s)
                  </Label>
                  <TextInput
                    type="text"
                    name="first_name"
                    id ="first_name_input"
                    // ref={register3}
                    placeholder="First Name"
                    isError={firstNameError}
                    defaultValue={firstName}
                    onChange={(e) => updateFirstName(e)}
                    />
                  <ErrorMessage c1 regular colorError>
                    {firstNameError && 'Please enter your first name'}
                  </ErrorMessage>
                </FormFieldWrapper>
              </Col>
              <Col md={6}>
                <FormFieldWrapper>
                  <Label c1 semibold textoffblack>
                    Last Name(s)
                  </Label>
                  <TextInput
                    type="text"
                    name="last_name"
                    // ref={register3}
                    placeholder="Last Name"
                    isError={lastNameError}
                    defaultValue = {lastName}
                    onChange = {(e) => updateLastName(e)}
                  />
                  <ErrorMessage c1 regular colorError>
                    {lastNameError && 'Please enter your last name'}
                  </ErrorMessage>
                </FormFieldWrapper>
              </Col>
            </Row>
          {/* Profession && Practice */}
            <Row>
              <Col md={12}>
                <FormFieldWrapper>
                  <Label c1 semibold textoffblack>
                    Profession
                  </Label>
                    <Select
                    styles={{
                      singleValue: (styles) => ({ 
                        ...styles,
                         color:"#212452",
                        }),
                      // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                      placeholder: (styles) => ({ 
                        ...styles,
                         backgroundColor:"#FBFBFD",
                         color: "#97AAB6",
                         fontWeight: "400",
                         opacity: "1",
                        }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '15px',
                        border:' 1px solid #E2E5ED',
                        backgroundColor:'#fbfbfd',
                        color: "#212452",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#212452",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#8771DF',
                      },
                    })}
                     className="profession"
                     classNamePrefix="select"
                     unstyled ={false}
                     onChange={(e) => updateProfession(e)}
                     options={ getListOfProfessions()} 
                     defaultValue={ userContext['profession']?{'value': userContext['profession'], 'name':userContext['profession'],'label':userContext['profession']}:'Select' }
                     isClearable={false}
                     isSearchable={false}
                    //  placeholder={  {'value': 'select', 'name':'select','label':'select'} }
                    >
                    </Select>
                  <ErrorMessage c1 regular colorError>
                    {professionError && 'Please select your profession'}
                  </ErrorMessage>
                  {selectedProfession === "Other" ?  (
                    <>
                  <TextInput
                    textoffblack
                    type="text"
                    name="profession_other"
                    placeholder={"Enter your profession"}
                    defaultValue={profession}
                    value={profession}
                    isError={professionOtherError}
                    onChange={(e) => updateProfessionOther(e)}
                    style={{ display: `${selectedProfession === "Other" ? 'block' : 'none'}` }}
                  />
                  <ErrorMessage c1 regular colorError style={{ display: `${professionOtherError? 'block' : 'none'}` }}>
                    {professionOtherError && 'Please enter your profession'}
                  </ErrorMessage></>
                  ):(
                    <>
                  <Label c1 semibold textoffblack
                    style={{ display: `${(typeof dict_of_professional_num[selectedProfession] !== 'undefined') ? 'block' : 'none'}` }}
                  >
                      { typeof dict_of_professional_num[selectedProfession] !== 'undefined' && dict_of_professional_num[selectedProfession] }
                  </Label>
                  <TextInput
                    type="text"
                    name="registration_number"
                    id ="registration_number_input"
                    defaultValue={registrationNumber}
                    onChange = {(e) => updateRegistrationNumber(e)}
                    value={registrationNumber}
                    style={{ display: `${typeof dict_of_professional_num[selectedProfession] !== 'undefined' ? 'block' : 'none'}` }}
                    isError={registrationNumberError}

                  />
                  {(registrationNumberError && (selectedProfession === "GP/GP Partner" || selectedProfession === "Locum GP") ) && 
                    <ErrorMessage c1 regular colorError>Please enter your registration number</ErrorMessage> 
                  }
                  </>
                  )}
                </FormFieldWrapper>
              </Col>
              <Col md={12}>
                <Label c1 semibold textoffblack  >
                  Institution
                </Label>
                <FormFieldWrapper>
                  <GoogleSuggest 
                    setBlockNext={setBlockNext}
                    setLoadingIcb={setLoadingIcb}
                    blockGetVersion={blockGetVersion} 
                    setLocation={setLocationFromChild} 
                    location={userContext['location'] && typeof userContext['location'] == "string" && userContext['location'] !== ''?userContext['location']:location} 
                    setPlace={setPlace} 
                    setBlockGetVersion={setBlockGetVersion} 
                    isError={locationError}
                    fromAccountPage = {false}
                    ></GoogleSuggest> 
                  
                </FormFieldWrapper>
              </Col>
              {loadingIcb &&(
                        <LoadingIndicator/>
              )}
              {!blockNext && icbs && typeof icbs === 'object' && icbs.length >0  &&  icbs[0] !== null && !loadingIcb && defaultIcb &&(
              <Col md={12} className='mb-3'>
                <Label c1 semibold textoffblack  >
                  ICB 
                </Label>
                <FormFieldWrapper>
                <Select
                    styles={{
                      singleValue: (styles) => ({ 
                        ...styles,
                         color:"#212452",
                        }),
                      // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                      placeholder: (styles) => ({ 
                        ...styles,
                         backgroundColor:"#FBFBFD",
                         color: "#97AAB6",
                         fontWeight: "400",
                         opacity: "1",
                        }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '15px',
                        border:' 1px solid #E2E5ED',
                        backgroundColor:'#fbfbfd',
                        color: "#212452",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#212452",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#8771DF',
                      },
                    })}
                     className="icbs"
                     classNamePrefix="select"
                     unstyled ={false}
                     onChange={(e) => updateIcb(e)}
                     isClearable={false}
                     isSearchable={true}
                     key={defaultIcb['icb_name']}
                     defaultValue={ {'value': defaultIcb['ods_code'], 'name':defaultIcb['icb_name'],'label':defaultIcb['icb_name']} ||'Select' }
                     options={ icbs.map((x)=> {
                      if( x && x.ods_code && x.icb_name){
                      return {'value': x.ods_code , 'name':x.icb_name,"label":x.icb_name };}}  ) } 
                    >
                    </Select>
                  
                  {/* we only want to add this Span  if registry number is selected  */}

                </FormFieldWrapper>
              </Col>)}
              {!blockNext && versions && versions.length> 0 && typeof versions === 'object' && !loadingIcb && (
              <Col md={12} className='mb-3'>
                <Label c1 semibold textoffblack  >
                  Medwise version 
                </Label>
                <FormFieldWrapper>
                <Select
                    styles={{
                      singleValue: (styles) => ({ 
                        ...styles,
                         color:"#212452",
                        }),
                      // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                      placeholder: (styles) => ({ 
                        ...styles,
                         backgroundColor:"#FBFBFD",
                         color: "#97AAB6",
                         fontWeight: "400",
                         opacity: "1",
                        }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '15px',
                        border:' 1px solid #E2E5ED',
                        backgroundColor:'#fbfbfd',
                        color: "#212452",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#212452",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#8771DF',
                      },
                    })}
                     key={defaultVersion['name']}
                     className="version"
                     classNamePrefix="select"
                     unstyled ={false}
                     onChange={(e) => updateVersion(e)}
                     isClearable={false}
                     isSearchable={false}
                     defaultValue={ {'value': defaultVersion['name'], 'name':defaultVersion['name'],'label':defaultVersion['name']} || 'Select'}
                     options={ versions.map((x)=> {return {'value': x.id , 'name':x.name,'label':x.name} ;}  ) } 
                    >
                    </Select>
                  
                  {/* we only want to add this Span  if registry number is selected  */}

                </FormFieldWrapper>
              </Col>)}
            </Row>

          </div>
          {/* Next button */}
            <Button
              // id="info_submitted"
              disabled={ blockNext || ( !defaultVersion )? true:false}
              type="button"
              colorprimary
              buttonlarge
              text= {organisation ? 'Done' : "Next"}
              width="100%"
              onClick={handleStepThreeNavigation}
            />
        </form>

        {notLoading && step === 4 && !isLoadingDefault && (
          <SignUpStepAddSources 
            icbName = {icbName}
            addCustomSources={addCustomSources}
            suggestedSources ={suggestedSources}
            organisationSources={organisationSources} 
            setNextStep = {setStep}
            step={step}
          ></SignUpStepAddSources>

        )}
      </Container>
    </Container>
  );
};

export default SignUpFlow;
