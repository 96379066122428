// This is an app-wide state, put stuff in here that you'll want to use in other components
// Make sure to export it at the end
import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import axios from 'axios';
import { is_single_source, single_source, sources, product_name, send_logs } from '../functions/frontend_variables';
import { getDefaultFilters, getUser, getDefaultSources, setUserSourceIds, setResultSources, getResultSources, 
  setResultSourceNames,getDefaultFiltersFromContext } from '../functions/manageSessions';
import { askAsync } from '../functions/utils';
import { useLocalStorage } from '../useStorage/useStorage.js';
import useIsSmallScreen from '../useIsSmallScreen/useIsSmallScreen.js';
import { countWords } from '../functions/utils';
import {english_speaking_codes} from '../functions/english_speaking_country_code.js';


const AppContext = React.createContext();
const AppProvider = (props) => {
  const NINETEENMINUTES = 19*60*1000;
  const isSmallScreen= useIsSmallScreen();
  const location = useLocation();
  const [exampleCarouselCards, setExampleCarouselCards] = useState(undefined);
  const [parsedCarouselCards, setParsedCarouselCards] = useState(undefined);
  // const [preselectPublisherFacets, setPreselectPublisherFacets] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [searchContext, setSearchContext] = useState({
    q: undefined,
    facets: getDefaultSources(),
    covid_only: false,
    demo_q_clicked: false,
    "from-trending": false,// to do place from-trending into event
    event: 'search' ,
    forceSearch: false,
    // Prevent beta server queries from showing up in logs
    eval_mode: window.location.hostname.match('beta') ? true : false
  });
  const [chatContext, setChatContext] = useState({
    q: undefined,
    sources: getDefaultSources()
  });
  const [goToChatPage, setGoToChatPage] = useState(false);
  const [askResTemp, setAskResTemp] = useState(null);
  // const [assistedRes, setAssistedRes] = useState([]);
  // const [defaultSources, setDefaultSources] = useState(getDefaultSources());
  const [tooManyRequestsError, setTooManyRequestsError] = useState (false);
  const [showCovidSwitch, setShowCovidSwitch] = useState(false)
  const [fetchedResults, setFetchedResults] = useState(undefined);
  const [chatIndex, setChatIndex] = useState(0);
  const [activeChatIndex, setActiveChatIndex] = useState(undefined);
  const [fetchedFilters, setFetchedFilters] = useState(undefined);
  const [fetchedQuery, setFetchedQuery] = useState(undefined);
  const [replacedQuery , setReplacedQuery] = useState(undefined);
  const [fetchedAdvancedSuggestions, setFetchedAdvancedSuggestions] = useState(undefined);
  const [fetchedSuggestedQuestion, setFetchedSuggestedQuestion] = useState([]);// empty array signal loading
  const [possibleAnswer, setPossibleAnswer] = useState([]);
  const [counter, setCounter] = useState(0);
  const [trackers, setTrackers] = useState([0, 0, 0, 0, 0]);
  const [machineResults, setMachineResults] = useState({0: [], 1: [], 2: [], 3: [], 4: []});
  const [numMachineResults, setNumMachineResults] = useState([0, 0, 0, 0, 0]);
  const [webResults, setWebResults] = useState({0: [], 1: [], 2: [], 3: [], 4: []});
  const [localWebResults, setLocalWebResults] = useState({0: [], 1: [], 2: [], 3: [], 4: []});
  const [clearLocalWebResults, setClearLocalWebResults] = useState();
  const [originalNumResults, setOriginalNumResults] = useState([0, 0, 0, 0, 0]);
  const [resultAnswerError, setResultAnswerError] = useState(false);
  const [logInitialDialog, setLogInitialDialog] = useState(false);
  const [searchNum, setSearchNum] = useState(undefined);
  const [dialogIDs, setDialogIDs] = useState([undefined, undefined, undefined, undefined, undefined]);
  const [queries, setQueries] = useState([]);
  const [waitForNextAnswer, setWaitForNextAnswer] = useState(false);
  const [needSeparateComposeAnswer, setNeedSeparateComposeAnswer] = useState(false);
  const [separateComposeAnswerPayload, setSeparateComposeAnswerPayload] = useState({
    body: undefined,
    sourcesId: undefined,
    index: undefined
  });
  const [needSeparateLogDialog, setNeedSeparateLogDialog] = useState(false);
  const [separateLogDialogPayload, setSeparateLogDialogPayload] = useState({
    log_body: undefined,
    index: undefined
  });
  const [logAIAssistedResults, setLogAIAssistedResults] = useState(false);
  const [chatSources, setChatSources] = useState('');
  const [userReplies, setUserReplies] = useState(undefined);
  const [queueDone, setQueueDone] = useState(true);
  const [fetchDialog, setFetchDialog] = useState(false);
  const [fetchAnswers, setFetchAnswers] = useState([undefined, undefined, undefined, undefined, undefined]);
  const [fetchedDialog, setFetchedDialog] = useState({0: [], 1: [], 2: [], 3: [], 4: []});
  const [fetchResults, setFetchResults] = useState([undefined, undefined, undefined, undefined, undefined]); // get-answer-from-result
  const [loadMore, setLoadMore] = useState(undefined);
  const [changeWeb, setChangeWeb] = useState(undefined);
  const [newQuestion, setNewQuestion] = useState([undefined]);
  const [fetchedMoreLikeThis, setFetchedMoreLikeThis] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [requestFilters, setRequestFilters] = useState(false);
  const [requestTrackerContext, setRequestTrackerContext] = 
          useState({
                    "get-organisation":false,
                    "get-summary":false,
                    "get-filters":false,
                    "get-messages":false,
                    // "log-search-results":false,
                    // "log-result-interaction":false,
                    "report-issue":false,
                    "api/ask":false,
                    "loginPage":false,
                    "headerLogin":false,
                    "signupPage":false,
                  });
  const [skipRequests, setSkipRequests] = useState(false);
  const [userId ,setUserId]= useState(0);
  const [requestUser ,setRequestUser]= useState(false);
  const [currentOrgName ,setCurrentOrgName]= useState(undefined);
  const [lastUpdated,setLastUpdated]= useState(undefined);
  const [progress, setProgress]= useState(0);
  const [isResultsExpanded, setIsResultsExpanded] = useState(false);
  const [resultsFirstExpanded, setResultsFirstExpanded] = useState(undefined);
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  // This is heavily used in the searchbar component.
  // The reason we have it in the global context is to keep the question inside the searchbar after search
  const [mobileContext, setMobileContext] = useState({
    label: 'Off',
    apply: false,
    clear: false,
    toggle: false,
    facetEntries: getDefaultFilters(),
    covidSwitch: false,
    forceSearch: false
  });
  const [feedbackContext, setFeedbackContext] = useState({
    activeFeedback: null,
    chunk_id: '',
    chunk_text: '',
    feedbackType: '',
    triggerRequest: false,
    mobile: { toggle: false },
    serverResponse: '',
  });
  const [reportIssue, setReportIssue] = useState({
    message: undefined,
    triggerRequest: false,
  });
  const [userContext, setUserContext] = useLocalStorage ('user_info', {'email':'', 'org_name':undefined});
  const [userContextFilters, setUserContextFilters, removeUserContextFilters] = useLocalStorage('user_filters', undefined);
  const [userStats, setUserStats] = useState(undefined);
  const [userOrganisationContext, setUserOrganisationContext, removeUserOrganisationContext] = useLocalStorage('orgInfo', undefined);
  const [anonQuery, setAnonQuery,removeAnonQuery] = useLocalStorage('anonQuery', '');
  const [assistedAiAllowWebUsage, setAssistedAiAllowWebUsage] = useState(true);
  const [showAssistedAI, setShowAssistedAI] = useState(true);
  const [showAssistedAIFromStart, setShowAssistedAIFromStart] = useState(true);
  const [assistedAiAutoexpandWordCount, setAssistedAiAutoexpandWordCount] = useState(4);
  const [confirmCouldNotFind, setConfirmCouldNotFind] = useState(false);
  const [suspectCouldNotFind, setSuspectCouldNotFind] = useState(false);
  // this variable is needed to avoid the infinit loop 
  // ask => currentQuery => translate
  // const [translate,setTranslate]= useState( false);
  const [useTranslatedQuery,setUseTranslatedQuery]= useState(false);
  const [translatedQuery,setTranslatedQuery] = useState({'q':'','translation':''});
  // const [queryIsTranslated, setQueryIsTranslated] = useState(false);
  // if user does has no country we assume it is english speaking
  // if user country is not majority native English speakin it is not english speaking
  //if browser language is english then we assume it is an english speaker
  const isEnglishSpeaker = useMemo(() => {
    let englishSpeaker = true; 
    let list_country = english_speaking_codes; 
    if( userContext && Object.hasOwn(userContext,'location_short_name')
        && !list_country.includes(userContext['location_short_name']) ){
      englishSpeaker = false;
      }
    //if browser language is english then we assume english speaker
    // order of if statement matter
    // if(window.navigator.language && window.navigator.language.split('-').length
    //    && window.navigator.language.split('-')[0] == 'en'
    //   ){
    //     englishSpeaker = true; 
    //   }
    return englishSpeaker;
  }, [userContext]);

  // this function forces an empty search
  // it is use when there is an error from backend
  // TODO it replace brwoser calls for hooks and update the hooks
  const push_empty_search = function(){
    setCurrentSearchTerm(" ");
    setFetchedResults([]);
    setFetchedQuery(undefined);
    setReplacedQuery(undefined);
    setFetchedAdvancedSuggestions(undefined);
    setFetchedMoreLikeThis([]);
    setMobileContext({ ...mobileContext, toggle: false });
    setIsLoading(false);
    let pathnameForSearch = '/search';
    if (getUser() && userOrganisationContext &&userOrganisationContext["alias"]) {
      pathnameForSearch = '/'+userOrganisationContext["alias"]+'/search';
    };
    props.history.push({
      pathname: pathnameForSearch,
      search: `?q=`,
    });
  };

  // this function forces a search with query but with no sources 
  // it is use when there is an error from backend
  // TODO it replace brwoser calls for hooks and update the hooks
  const push_no_sources_search = function(q){
    setCurrentSearchTerm(q);
    setFetchedResults([]);
    setFetchedQuery(undefined);
    setReplacedQuery(undefined);
    setFetchedAdvancedSuggestions(undefined);
    setFetchedMoreLikeThis([]);
    setMobileContext({ ...mobileContext, toggle: false });
    setIsLoading(false);
    let pathnameForSearch = '/search';
    if (getUser() && userOrganisationContext &&userOrganisationContext["alias"]) {
      pathnameForSearch = '/'+userOrganisationContext["alias"]+'/search';
    };
    let urlParam =`?q=${q}&sources=` ;
    props.history.push({
      pathname: pathnameForSearch,
      search: urlParam,
    });
  };

  const handleUpdate = () => {
    if( typeof lastUpdated === "undefined"  ||  lastUpdated && Date.now() -lastUpdated > NINETEENMINUTES )
    {
      setRequestUser(true);
      window.removeEventListener('pointermove', handleUpdate);
      window.removeEventListener('keydown', handleUpdate);
      window.removeEventListener('click', handleUpdate);
    }
};

  const requestOrgInfo = ()  => {
    // setIsWaitingForRequest(true);
    if (location.pathname !== '/deep-ai-search')
      setRequestTrackerContext(prev => { return {...prev, 'get-organisation':true}});
    axios
    .get(`${window.BASE_URL_USER}get-organisation`, {withCredentials: true})
    .then((res) => {
      // setIsWaitingForRequest(false);
      setRequestTrackerContext(prev => { return {...prev, 'get-organisation':false}});

      if (res.data) {
       // received data
       if(userContext["OrgName"] && typeof userContext["OrgName"] === "string"  ){
        setCurrentOrgName( userContext["OrgName"] ); // orgIfno is not call unless currentOrgName is dif or undefined
        }
        // setOrganisationSession(res.data);
        if(!res.data.code){
          setUserOrganisationContext(res.data);
        }
      } else {
        removeUserOrganisationContext();
        // setUserOrganisationContext(undefined);
        console.log(
          'The request is successful, but for some reason res.data is not received: \n',
          res
        );
      }
    })
    .catch((error) => {
      // setUserOrganisationContext(undefined);
      removeUserOrganisationContext();
      setRequestTrackerContext(prev => { return {...prev, 'get-organisation':false}});
      // setIsWaitingForRequest(false);
      console.log('get-org',error);
    });
  }; 

  const requestGetSummary = function(){
    setRequestTrackerContext(prev => { return {...prev, 'get-summary':true}});
    axios({
        method: 'POST',
        url: window.BASE_URL_USER+'get-summary',
        withCredentials: true,
        // signal: controller.signal,
        // cancelToken: source.token,
    })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'get-summary':false}});
            if(typeof res.data['num_searches'] !== undefined ){
              setUserStats({
                'num_searches': res.data['num_searches'],
                'num_grouped_searches': res.data['num_grouped_searches'],
                'num_bookmarks': res.data['num_bookmarks'],
                'num_cpd_entries': res.data['num_cpd_entries'],
                'unread_feedback': res.data['unread_feedback'],
              });
            }
            if (res.data.user){
              // setUserSession(res.data.user);
              setUserContext(res.data.user);
              setLastUpdated(Date.now());
              // TODO: do not always triger get filters
              requestSources();
            }

        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'get-summary':false}});
          // setIsWaitingForRequest(false);
          console.log("get-summary",error);
        });
  };

  const requestDialog = function (body, sourcesID, index, isSeparateCall) {
    // Hard refresh chat page OR new follow-up question
    if (location.pathname === '/deep-ai-search') {
      setRequestTrackerContext(prev => { return {...prev, 'dialog':true}});
    } 
    // else { // Search page
    // };
      
    axios({
      method: 'POST',
      url: `${window.BASE_URL_ASSISTED}compose-answer`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: JSON.stringify(body),
      signal: controller.signal,
      cancelToken: source.token,
    })
      .then((res) => {
        const updatedFetchAnswers = [...fetchAnswers];
        updatedFetchAnswers[index] = false;
        setFetchAnswers(updatedFetchAnswers);
        setRequestTrackerContext(prev => { return {...prev, 'dialog':false}});
        if (res.data) {
          setFetchedDialog(fetchedDialog => ({...fetchedDialog, [index]: [...fetchedDialog[index], res.data['machine_reply_answer']]})); 

          if (isSeparateCall) {
            // Cancel this separate call marker
            setNeedSeparateComposeAnswer(false);
            setSeparateComposeAnswerPayload({
              body: undefined,
              sourcesId: undefined,
              index: undefined
            });
          };

          let log_body = {
            "results": {
              "machine_reply_results": machineResults[index],
              "composed_answer": res.data['machine_reply_answer'],
            },
            "search_num": searchNum
          };

          if (loadMore) {
            log_body.dialog_id = dialogIDs[index];
          } else {
            log_body.q = queries[index];
            log_body.get_filters_response = userContextFilters;//TODO make sure it is properly sync 
            log_body.selected_sources = sourcesID;
            log_body.has_mobile_version = false;
            if (index === 0) {
              log_body.suggested_question = fetchedSuggestedQuestion.at(0);
              log_body.dialog_id = dialogIDs[index];
              log_body.first_loaded = true;
            };
          };

          if (location.pathname === '/deep-ai-search') {
            // Final log dialog
            add_dialog_to_logs(log_body, index, true, false);
          };
        };
      })
      .catch((error) => {
        const updatedFetchAnswers = [...fetchAnswers];
        updatedFetchAnswers[index] = false;
        setFetchAnswers(updatedFetchAnswers);

        setRequestTrackerContext(prev => { return {...prev, 'dialog':false}});
        if (error && error.response && error.response.data.code === 400) {
          var system_msg = "Nothing was found. Consider changing sources e.g. <span class='web-change1'>whole Web</span> or click <span class='load-more1'>load more</span> to keep searching.";
        } else if (error && error.response && error.response.data.code === 500) {
          var system_msg = "An error has occurred. We are looking into it. Please try again in a few minutes.";
        };

        setFetchedDialog(fetchedDialog => ({...fetchedDialog, [index]: [...fetchedDialog[index], system_msg]}));

        if (isSeparateCall) {
          // Cancel this separate call marker
          setNeedSeparateComposeAnswer(false);
          setSeparateComposeAnswerPayload({
            body: undefined,
            sourcesId: undefined,
            index: undefined
          });
        };
        
        let log_body = {
          "results": {
            "machine_reply_results": machineResults[index],
            "composed_answer": system_msg,
          },
          "search_num": searchNum
        };

        if (loadMore) {
          log_body.dialog_id = dialogIDs[index];
        } else {
          log_body.q = queries[index];
          log_body.get_filters_response = userContextFilters;//TODO make sure it is properly sync 
          log_body.selected_sources = sourcesID;
          log_body.has_mobile_version = false;
          log_body.results.possible_answer = possibleAnswer[index];
          if (index === 0) {
            log_body.suggested_question = fetchedSuggestedQuestion.at(0);
            log_body.dialog_id = dialogIDs[index];
            log_body.first_loaded = true;
          };
        };

        if (location.pathname === '/deep-ai-search') {
          // Final log dialog
          add_dialog_to_logs(log_body, index, true, false);
        };
        // setIsWaitingForRequest(false);
        console.log("dialog", error);
      });
  };

  const getSuggestedQuestion = function (res, body, body1, q) {
    // this does not get called if we already have a translatedQuery
    // if > NINETEENMINUTES we get the user and then request translation (which triggers a 'new ask') 
    axios({
      method: 'POST',
      url: `${window.BASE_URL_ASSISTED}suggest-question`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: JSON.stringify({q: Object.hasOwn(res, 'data')&& Object.hasOwn(res.data, 'results_info') &&
      Object.hasOwn(res.data.results_info, 'replaced_query') && Object.hasOwn(res.data.results_info.replaced_query, 'text') 
      && res.data.results_info.replaced_query.text !== '' ? res.data.results_info.replaced_query.text : body.q}),
    })
      .then((res1) => {
        if (res1.data.suggested_question && res1.data.suggested_question.length) {
          // Step 2
          let body2 = {q: body.q, sources: body1.sources};
          if ( Object.hasOwn(res,'data')&& Object.hasOwn(res.data,'results_info') 
              && Object.hasOwn(res.data.results_info,'replaced_query') 
            && res.data.results_info.replaced_query.text && res.data.results_info.replaced_query.text !== '') {
            body2.q = res.data.results_info.replaced_query.text;
            body1.q = res.data.results_info.replaced_query.text;
          };
          requestPossibleAnswer(body2, body1, chatIndex);
          setQueries(queries => [...queries, q]);
          setFetchedSuggestedQuestion(fetchedSuggestedQuestion => [...fetchedSuggestedQuestion, res1.data.suggested_question]);

          if (location.pathname === '/deep-ai-search') {
            setLogInitialDialog(true);
          };
        }
        else{
          setFetchedSuggestedQuestion(undefined);// undefined signals loading to results-cards
        };
      });

  };

  // only called if ask request got stop
  const getTranslation = function (original_q) {
    if(translatedQuery && Object.hasOwn(translatedQuery,'q') 
      && Object.hasOwn(translatedQuery,'translation') && typeof translatedQuery['q'] != 'undefined' 
      && typeof translatedQuery['translation'] != 'undefined' && translatedQuery['q'].trim() 
      && translatedQuery['translation'].trim() && translatedQuery['q'].trim() === original_q ){
      setUseTranslatedQuery(true);
      setSearchContext({
        ...searchContext,
        forceSearch:true,
      });
    }
    else{
      setRequestTrackerContext(prev => { return {...prev, 'translate':true}});
      axios({
        method: 'POST',
        url: `${window.BASE_URL_ASSISTED}suggest-question`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: JSON.stringify({q:original_q}),
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'translate':false}});
          if (res.data.suggested_question && typeof res.data.suggested_question =='string' 
              && res.data.suggested_question.trim().length) {
            setUseTranslatedQuery(true);
            setTranslatedQuery({
              'q':original_q,
              'translation':res.data.suggested_question});
            setSearchContext({
              ...searchContext,
              forceSearch:true,
            });
          }
          else{
            setUseTranslatedQuery(true);
            // setTranslatedQuery(original_q);// if we do not find anything we still need to do a search
            setTranslatedQuery({
              'q':original_q,
              'translation':res.data.suggested_question});
            setSearchContext({
              ...searchContext,
              forceSearch:true,
            });
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'translate':false}});
          setUseTranslatedQuery(true);
          setTranslatedQuery({
            'q':original_q,
            'translation':original_q});
          // setTranslatedQuery(original_q);// if we do not find anything we still need to do a search
          setSearchContext({
            ...searchContext,
            forceSearch:true,
          });
        });
    }

  };

  const requestPossibleAnswer = function (body, body1, index) {
    // Hard refresh chat page OR new follow-up question
    if (location.pathname === '/deep-ai-search') {
      setRequestTrackerContext(prev => { return {...prev, 'possible-answer':true}});
    } 
    // else { // Search page
    // };
    setFetchDialog(true);
      
    axios({
      method: 'POST',
      url: `${window.BASE_URL_ASSISTED}possible-answer`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: JSON.stringify(body),
      signal: controller.signal,
      cancelToken: source.token,
    })
      .then((res) => {
        setFetchDialog(false);
        setRequestTrackerContext(prev => { return {...prev, 'possible-answer':false}});
        if (res.data) {
          setPossibleAnswer(possibleAnswer => [...possibleAnswer, res.data.possible_answer]);
        };

        const updatedFetchResults = [...fetchResults];
        updatedFetchResults[index] = true;
        setFetchResults(updatedFetchResults);

        const updatedFetchAnswers = [...fetchAnswers];
        updatedFetchAnswers[index] = true;
        setFetchAnswers(updatedFetchAnswers);

        // Step 3
        getAnswer(body1, 1, index, true);
        getAnswer(body1, 2, index, false);
      })
      .catch((error) => {
        setFetchDialog(false);
        setRequestTrackerContext(prev => { return {...prev, 'possible-answer':false}});
        // setIsWaitingForRequest(false);

        console.log("possible answer", error);
      });
  };

  const getAnswer = function (body, tracker, index, triggerServerError) {
    // Hard refresh chat page OR new follow-up question
    if (location.pathname === '/deep-ai-search') {
      setRequestTrackerContext(prev => { return {...prev, 'get-machine-results':true}});
    };
    //   setFetchDialog(true);
    // } else { // Search page
    //   setFetchDialog(true);
    // };
    body.tracker = tracker;

    // we do this bc sometimes when we change filters, we also show the machine results from previous filter selection
    // if (tracker === 1 && location.pathname !== '/deep-ai-search') {
    //   setMachineResults({0: [], 1: [], 2: [], 3: [], 4: []});
    // }
    
    if (tracker > 10 && location.pathname !== '/deep-ai-search') { // we activate the web search bc nothing was found before
      body.sources = 'custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=';
      body.tracker = body.tracker - 10; 
    };
    axios({
      method: 'POST',
      url: `${window.BASE_URL_ASSISTED}get-answer-from-result`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: JSON.stringify(body),
      signal: controller.signal,
      cancelToken: source.token,
    })
      .then((res) => {
        // setFetchDialog(false);
        setRequestTrackerContext(prev => { return { ...prev, 'get-machine-results': false }});
        if (res.data && res.data.machine_reply_result && res.data.machine_reply_result.text) {
          setProgress(100);
          const machine_reply_result = res.data['machine_reply_result'];
          machine_reply_result.tracker_number = tracker;
          if (body.sources === 'custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=') {
            setWebResults(webResults => ({...webResults, [index]: [...webResults[index], machine_reply_result]}));
            if (clearLocalWebResults) {
              setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
            } else {
              setLocalWebResults(localWebResults => ({...localWebResults, [index]: [...localWebResults[index], machine_reply_result]}));
            };
          } else {
            setMachineResults(machineResults => ({...machineResults, [index]: [...machineResults[index], machine_reply_result]}));
          };
        };
        setCounter(prev => prev + 1);
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-machine-results': false}});
        // setIsWaitingForRequest(false);
        if (triggerServerError && !(error.message && error.message.startsWith(" Operation canceled by cleanup"))) 
        {
          setResultAnswerError(true);      
        }
        // else{
        //   setProgress(100); // only set to 100 if we dont trigger an other call
        // }
        console.log("get answer from result", error);
      });
  };

  useEffect(() => {
    setNumMachineResults([
      machineResults[0].length + webResults[0].length,
      machineResults[1].length + webResults[1].length,
      machineResults[2].length + webResults[2].length,
      machineResults[3].length + webResults[3].length,
      machineResults[4].length + webResults[4].length,
    ]);
  }, [machineResults, webResults]);

  useEffect(() => {
    if (resultsFirstExpanded === undefined && numMachineResults[0] !== 0 && location.pathname !== '/deep-ai-search') {
      setLogAIAssistedResults(true);
    };
  }, [numMachineResults, resultsFirstExpanded]);

  useEffect(() => {
    if (loadMore && numMachineResults) {
      setLogAIAssistedResults(true);
      if (resultsFirstExpanded) {
        setResultsFirstExpanded(undefined);
      };
    };
  }, [numMachineResults]);

  useEffect(() => {
    if ((logAIAssistedResults || resultsFirstExpanded) && askResTemp && machineResults[0].length) {
      let selectedSourcesIDs = [];
      let facets = searchContext.facets;
      if ((!is_single_source) && facets && facets.length !== 0) {
        searchContext.facets.forEach(source => {
          // if it is a src object 
          if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
          //check source is valid before sending a request
            if(source.hasOwnProperty('id')){
              selectedSourcesIDs.push(source['id']);
            };
          }
          // else it is not a src but an display filter add array of sources
          else {
            if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
              source['sources'].forEach(source => {
                if(source.hasOwnProperty('id')){
                  selectedSourcesIDs.push(source['id']);
                }
              });
            }
          }
        });
      };

      let log_body = {
        get_filters_response: userContextFilters, //TODO make sure it is properly sync 
        suggested_question: fetchedSuggestedQuestion.at(0),
        has_mobile_version: isSmallScreen,// 
        q: searchContext.q,
        ask_response: askResTemp,
        assisted_response: machineResults[0],
        selected_sources: selectedSourcesIDs
      };
      // For Assisted AI Results
      add_search_to_logs(log_body);
      setLogAIAssistedResults(false);
    };
  }, [logAIAssistedResults, resultsFirstExpanded, askResTemp]);

  const requestSources = function() {
    // setIsWaitingForRequest(true); 
    if (location.pathname !== '/deep-ai-search')
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':true}});
    axios({
      method: 'POST',
      url: window.BASE_URL_USER+'get-filters',
      withCredentials: true,
      signal: controller.signal,
      cancelToken: source.token,
    })
    .then((res) => {
      // setIsWaitingForRequest(false);
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':false}});
      if (res.data) {
        // Object.values(res.data).forEach(val=> console.log(val) );
        let localFilters;
        let allSourcesInUser= Object.keys(res.data);
        let dataToStore = res.data;
        let allSourcesFromResponse = [];
        let allSourceIdsFromResponse = [];
        Object.values(res.data).forEach((val)=>
          { 
            if(val && val.length >0  )
            {
              val.forEach(filter => {
                if(filter && typeof filter['sources'] !== 'undefined'){
                  filter['sources'].forEach(source => {
                    allSourcesFromResponse.push(source);
                    allSourceIdsFromResponse.push(source.id);
                  });
                }
              });
            }
          }
        );
        if (allSourcesFromResponse.length > 0) {
          let data_to_store ={...res.data,
                            "sources": allSourcesFromResponse};
          
          if( userContextFilters && userContextFilters['sources'])
          {
            let currentFilters = userContextFilters['sources'].flatMap( x => x['encrypted_url'] ).join();
            let newSources = allSourcesFromResponse.flatMap( x => x['encrypted_url'] ).join();
            if(currentFilters!==newSources){
              setUserContextFilters(data_to_store);
            }
          }
          else{
            setUserContextFilters(data_to_store);
          }
        } 
        if (allSourceIdsFromResponse.length > 0) {
          allSourceIdsFromResponse = [...new Set(allSourceIdsFromResponse)];
          setUserSourceIds(allSourceIdsFromResponse);
        }
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':false}});
      // setIsWaitingForRequest(false);
    });
  };

  const add_search_to_logs = function(body){
    if(!skipRequests){
      axios({
        method: 'post',
        url: `${window.BASE_URL_USER}log-search-results`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
        // we do not allow this request to be cancel
        // bc it is only trigger once an ask has been requested
        // signal: controller.signal,
        // cancelToken: source.token,
      })
        .catch((error) => {
          // if (axios.isCancel(error)) {
          //   console.log('add user log request canceled', error.message);
          // } else {
          console.log('Error: ', error);
        // }
        });
    }
  };

  const add_dialog_to_logs = function(body, index, isFinalLog, isSeparateLog){
    if(!skipRequests){
      axios({
        method: 'post',
        url: `${window.BASE_URL_USER}log-dialog`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
        // we do not allow this request to be cancel
        // bc it is only trigger once an ask has been requested
        // signal: controller.signal,
        // cancelToken: source.token,
      })
        .then((res) => {
          setSearchNum(res.data.search_num);

          const updatedDialogIDs = [...dialogIDs];
          updatedDialogIDs[index] = res.data.dialog_id;
          setDialogIDs(updatedDialogIDs);
          if (isFinalLog) {
            setQueueDone(true)
            setLoadMore(false);
          } else if (isSeparateLog) {
            setQueueDone(true);
            setNeedSeparateLogDialog(false);
            setSeparateLogDialogPayload({log_body: undefined, index: undefined});
          }
        })
        .catch((error) => {
          // if (axios.isCancel(error)) {
          //   console.log('add user log request canceled', error.message);
          // } else {
          console.log('Error: ', error);
          if (isFinalLog) {
            setQueueDone(true);
            setLoadMore(false);
          } else if (isSeparateLog) {
            setQueueDone(true);
            setNeedSeparateLogDialog(false);
            setSeparateLogDialogPayload({log_body: undefined, index: undefined});
          }
        // }
        });
    }
  };

  const cancelRequest = (num ) =>{
    source.cancel(' Operation canceled by cleanup . '+ num );
    controller.abort();
  };

  useEffect(() => {
    let mounted = true;
    if (feedbackContext.triggerRequest && searchContext) {
      let query = searchContext.q;
      let chunk_id = feedbackContext.chunk_id;
      let online_view_url = feedbackContext.online_view_url;
      let chunk_text = feedbackContext.chunk_text;
      let rank = feedbackContext.rank;
      let value = feedbackContext.value;
      let text = feedbackContext.text;
      let feedbackText = feedbackContext.feedbackText;
      let feedbackType = feedbackContext.feedbackType;
      let doc_publisher =  feedbackContext.doc_publisher;
      let chunk_title  = feedbackContext.chunk_title ;
      let sources = searchContext.facets.map(x=>x.name); 
      let body = {
        // chunk_id: chunk_id,
        online_view_url: online_view_url,
        // chunk_title: chunk_title,
        // doc_publisher: doc_publisher,
        // rank: rank, 
        // product: product_name,
        // "selected-filters": sources,
        q:query};

      // if (value) {
      //   body = {
      //     ...body,
      //     value: value,
      //   };
      // }

      // if (text) {
      //   body = {
      //     ...body,
      //     text: text,
      //   };
      // }
      // if (feedbackText && feedbackText.trim().length>0){
      //   body = {
      //     ...body,
      //     feedbackText: feedbackText,
      //   };
      // }

      // if (feedbackType !== 'vote' && feedbackType !== 'interact') {
      //   // I belive this is never used
      //   body = {
      //     ...body,
      //     chunk_text: chunk_text,
      //   };
      // }

      if (feedbackType){
        if(feedbackType === "interact"){
          feedbackType = "expand";
        }
        body = {
          ...body,
          "interaction_type": feedbackType,
        };
      }

      // let body = {
      //   q:query,
      // };

      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}log-result-interaction`,
        withCredentials : true, 
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
        signal: controller.signal,
        cancelToken: source.token,
      };
      // setIsWaitingForRequest(true);
      // setRequestTrackerContext(prev => { return {...prev, 'log-result-interaction':true}});
      axios(config)
        .then((res) => {
          // setIsWaitingForRequest(false);
          // setRequestTrackerContext(prev => { return {...prev, 'log-result-interaction':false}});
          if (mounted) {
            setFeedbackContext({
              ...feedbackContext,
              activeFeedback: null,
              chunk_id: '',
              chunk_text: '',
              feedbackType: feedbackType,
              triggerRequest: false,
              mobile: { toggle: false },
              serverResponse: {
                id: chunk_id,
                isSubmitted: res.data.success ? true : false,
                type: feedbackType,
                value: value ? value : false,
                text: text ? text : false,
              },
            });
          }
        })
        .catch((error) => {
          // setIsWaitingForRequest(false);
          // setRequestTrackerContext(prev => { return {...prev, 'log-result-interaction':false}});
          console.log('error: ', error);
        });
    }

    return function cleanup() {
      mounted = false;
      // canceling the pending requests
    };
    // eslint-disable-next-line
  }, [feedbackContext.triggerRequest]);

  useEffect(() => {
    const updatedMachineResults = {...machineResults};
    for (let i = 0; i < 5; i++) {
      updatedMachineResults[i].forEach(result => {
        result.dialog_id = dialogIDs[i];
      });
    };
    setMachineResults(updatedMachineResults); 
  }, [dialogIDs]);

  useEffect (()=>{
    let mounted = true;

    if (reportIssue.triggerRequest && reportIssue.message && reportIssue.parsed_args &&
      typeof reportIssue.message === 'string' && reportIssue.message.length > 0  )
      {
      let body ={"message" : reportIssue.message,
                  "parsed_args":reportIssue.parsed_args}
      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}report-issue`,
        withCredentials : true, 
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      };
      // setIsWaitingForRequest(true);
      setRequestTrackerContext(prev => { return {...prev, 'report-issue':true}});

      axios(config)
      .then((res) => {
        // setIsWaitingForRequest(false);
        setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});

      })
      .catch((error) => {
        // setIsWaitingForRequest(false);
        setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});
        console.log('error: ', error);
      });
    }
  },[reportIssue.triggerRequest]);

  
  useEffect(() => {
    // // Refresh chat page
    // if (location.pathname === '/chat') {
    //   console.log("hello chat page")
    // };
    // Go to chat page
    if (goToChatPage) {      
      let nameOfSources = '';
      let facets = searchContext.facets;

      if ((!is_single_source) && facets && facets.length !== 0) {
        facets.forEach(source => {
          // if it is a src object 
          if (source && source["encrypted_url"] && (source['name'] !== "Web" || facets.length === 1)) { 
            //check source is valid before sending a request
            if(source && source['name']){
              nameOfSources = nameOfSources + source['name'] + ',';
            }
          }
          // else it is not a src but an display filter add array of sources
          else {
            if(source && source['sources'] && (source['name'] !== "Web" || facets.length === 1)) {
              source['sources'].forEach(source => {
                if (source && source['name']){
                  nameOfSources = nameOfSources + source['name'] + ',';
                }
              });
            }
          }
        });
        nameOfSources = nameOfSources.substring(0, nameOfSources.length - 1); 
      };

      let urlParam = `?q=${searchContext.q}&sources=${nameOfSources}`;
      urlParam = encodeURI(urlParam);
      let pathnameForSearch = '/deep-ai-search';
      props.history.push({
        pathname: pathnameForSearch,
        search: urlParam,
      });

      if (location.pathname === '/deep-ai-search') {
        if (needSeparateComposeAnswer) {
          requestDialog(separateComposeAnswerPayload.body, separateComposeAnswerPayload.sourcesID, separateComposeAnswerPayload.index, true);
        } else if (needSeparateLogDialog) {
          add_dialog_to_logs(separateLogDialogPayload.log_body, separateLogDialogPayload.index, false, true);
        } else {
          // Click through from search page
          if (fetchedSuggestedQuestion.at(0)) {
            let selectedSourcesIDs = [];
            let facets = searchContext.facets;
            if ((!is_single_source) && facets && facets.length !== 0) {
              searchContext.facets.forEach(source => {
                // if it is a src object 
                if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
                //check source is valid before sending a request
                  if(source.hasOwnProperty('id')){
                    selectedSourcesIDs.push(source['id']);
                  };
                }
                // else it is not a src but an display filter add array of sources
                else {
                  if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
                    source['sources'].forEach(source => {
                      if(source.hasOwnProperty('id')){
                        selectedSourcesIDs.push(source['id']);
                      }
                    });
                  }
                }
              });
            };
  
            let log_body = {
              q: queries[0],
              suggested_question: fetchedSuggestedQuestion.at(0),
              get_filters_response: userContextFilters,//TODO make sure it is properly sync 
              selected_sources: selectedSourcesIDs,
              results: {},
              has_mobile_version: false, 
            };
            add_dialog_to_logs(log_body, 0, false, false);
          };
        };
      
        setGoToChatPage(false);
      }
    };
  }, [goToChatPage, location.pathname]);

  useEffect(() => {
    if (logInitialDialog) {
      let selectedSourcesIDs = [];
      let facets = searchContext.facets;
      if ((!is_single_source) && facets && facets.length !== 0) {
        searchContext.facets.forEach(source => {
          // if it is a src object 
          if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
          //check source is valid before sending a request
            if(source.hasOwnProperty('id')){
              selectedSourcesIDs.push(source['id']);
            };
          }
          // else it is not a src but an display filter add array of sources
          else {
            if (source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )) {
              source['sources'].forEach(source => {
                if (source.hasOwnProperty('id')) {
                  selectedSourcesIDs.push(source['id']);
                };
              });
            };
          };
        });
      };

      let log_body = {
        q: queries[0],
        suggested_question: fetchedSuggestedQuestion.at(0),
        get_filters_response: userContextFilters,//TODO make sure it is properly sync 
        selected_sources: selectedSourcesIDs,
        results: {},
        has_mobile_version: false, 
      };
      add_dialog_to_logs(log_body, 0, false, false);
      setLogInitialDialog(false);
    };
  }, [logInitialDialog]);

  useEffect(() => {
    if (queries && queries.length > 1) {
      setUserReplies(queries.slice(1));
    }
  }, [queries]);

// this function trigger the ask request after getting the filters
  const fetchFiltersAndtriggerAsk =()=> {
    // setIsWaitingForRequest(true); 
    if (location.pathname !== '/deep-ai-search')
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':true}});
     axios({
      method: 'POST',
      url: window.BASE_URL_USER+'get-filters',
      withCredentials: true,
      signal: controller.signal,
      cancelToken: source.token,
    })
    .then((res) => {
      // setIsWaitingForRequest(false);
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':false}});
      if (res.data) {
        // Object.values(res.data).forEach(val=> console.log(val) );
        let localFilters;
        let allSourcesInUser= Object.keys(res.data);
        let dataToStore = res.data;
        let allSourcesFromResponse = [];
        let allSourceIdsFromResponse = [];
        Object.values(res.data).forEach((val)=>
          { 
            if(val && val.length >0  )
            {
              val.forEach(filter => {
                if(filter && typeof filter['sources'] !== 'undefined'){
                  filter['sources'].forEach(source => {
                    allSourcesFromResponse.push(source);
                    allSourceIdsFromResponse.push(source.id);
                  });
                }
              });
            }
          }
        );
        if (allSourcesFromResponse.length > 0) {
          let data_to_store ={...res.data,
                            "sources": allSourcesFromResponse};
          
          if( userContextFilters && userContextFilters['sources'])
          {
            let currentFilters = userContextFilters['sources'].flatMap( x => x['encrypted_url'] ).join();
            let newSources = allSourcesFromResponse.flatMap( x => x['encrypted_url'] ).join();
            if(currentFilters!==newSources){
              // setUserFilters(res.data);
              setUserContextFilters(data_to_store);
            }
          }
          else{
            // setUserFilters(res.data);
            setUserContextFilters(data_to_store);
          }
          setSearchContext({
            ...searchContext,
            facets: getDefaultFiltersFromContext(data_to_store),
            // forceSearch: true,
          });
        } 
        if (allSourceIdsFromResponse.length > 0) {
          allSourceIdsFromResponse = [...new Set(allSourceIdsFromResponse)];
          setUserSourceIds(allSourceIdsFromResponse);
        }
        
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-filters':false}});
      // setIsWaitingForRequest(false);
    });
    };


  // ASK REQUEST  /// 
  /////////////////////////////////////////////////////////////////
  useEffect(()=> {
    let timeOutSummary; 
    let timeOutGetAnswser; 
    let mounted = true;
    let filtering;
    let nameOfSources='';
    let nameOfSources1='';
    var result="";
    if(typeof userContextFilters  === 'undefined'  || typeof searchContext.facets ==='undefined' 
        || searchContext.facets.length === 0){
      // if we have no facets we assume that there are no filters available
      // the component did not found any filters and sent searchContext.facets empty  
      // if we have no filters we need to wait for filters
      if(userContext['is_anonymous'] || typeof userContext === 'undefined'){
        fetchFiltersAndtriggerAsk();
      }
      if(!userContext['is_anonymous'] && userContext['email']!=='' ){
        setFetchedResults([]);
      }
    }

    // we need userContext bc the sources come from there 
    if (searchContext.q &&  !skipRequests &&  typeof userContextFilters  !== 'undefined' 
        && typeof searchContext.facets !=='undefined' && searchContext.facets.length !== 0) {
      // Note: Since searchContext.q is being set from different
      // components (such as SearchBar, MoreLikeThis and CarouselCards)
      let q = searchContext.q;
      let facets = searchContext.facets;
      let covid_only = searchContext.covid_only;
      // let demo_q_clicked = searchContext.demo_q_clicked;
      let is_trending = searchContext["from-trending"];
      let event_to_send = searchContext["event"];
      let body = { q: q, product: product_name};

      // let send_error = false;
      if (is_single_source){
        body = { q: q, product: product_name, sources: single_source};
      }
      let selectedSourcesIDs = [] ;
      if ((!is_single_source) && facets && facets.length !== 0) {
        filtering = true;
        searchContext.facets.forEach(source => {
          // if it is  a src  object 
          if (source &&  source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
          //check source is valid before sending a request
            // if (isValidSourceForAsk(source["encrypted_url"])){
              result =result + decodeURIComponent(source["encrypted_url"]) + "," ;
              if(source.hasOwnProperty('id')){
                selectedSourcesIDs.push(source['id']);
              }
              if(source['name']){
                nameOfSources = nameOfSources + source['name']+',';
                nameOfSources1 = nameOfSources1 + source['name']+', ';
              }
            // }
          }
          // else it is not a src but an display filter add array of sources
          else{
            if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
              source['sources'].forEach(source => {
                // if( isValidSourceForAsk(source)){
                    result =result + decodeURIComponent(source['encrypted_url']) + "," ;
                    if(source.hasOwnProperty('id')){
                      selectedSourcesIDs.push(source['id']);
                    }
                    if (source && source['name']){
                      nameOfSources = nameOfSources + source['name']+',';
                      nameOfSources1 = nameOfSources1 + source['name']+', ';
                    }
                  // }
              });
            }
          }
        });
        result = result.substring(0, result.length - 1);
        nameOfSources = nameOfSources.substring(0, nameOfSources.length - 1);
        nameOfSources1 = nameOfSources1.substring(0, nameOfSources1.length - 2);
        setResultSourceNames(nameOfSources1);
        setChatSources(nameOfSources1);
        setResultSources(result);
        body = {
          ...body,
          sources: result,
        };
      }
      else {
        push_no_sources_search(q);
      }
      
      if (covid_only) {
        body = {
          ...body,
          covid_only: true,
        };
      }

      if(userContext && userContext['search_params'] ){
        body = {
          ...body,
          search_params: userContext['search_params'],
          // frontend_request: !window.location.hostname.match('beta|localhost')
        };
      }
      if( useTranslatedQuery && !isEnglishSpeaker 
          && translatedQuery && Object.hasOwn(translatedQuery,'translation')
          && typeof translatedQuery['translation'] != 'undefined' && translatedQuery['translation'].trim()){
            body.q = translatedQuery['translation'].trim();
      }
      const config = {
        method: 'post',
        url: `${window.BASE_URL_ASK}api/ask`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: JSON.stringify(body),
        signal: controller.signal,
        cancelToken: source.token,
      };


      // ask needs a valid user searchParam , if needed , calling an async function is required
      if(((q !== '' && q !== currentSearchTerm) || searchContext.forceSearch === true) 
            && !skipRequests && Date.now() - lastUpdated < NINETEENMINUTES 
            && (isEnglishSpeaker || useTranslatedQuery)
           ) {
        // setIsWaitingForRequest(true);
        setFetchedResults(undefined);
        setReplacedQuery(undefined); // needed to remove different search notification 
        if (!newQuestion[chatIndex]) {
          // Reset to default states
          setFetchedSuggestedQuestion([]);
          setFetchedAdvancedSuggestions(undefined);
          setPossibleAnswer([]);
          setFetchedDialog({0: [], 1: [], 2: [], 3: [], 4: []});
          setMachineResults({0: [], 1: [], 2: [], 3: [], 4: []});
          setWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
          setNumMachineResults([0, 0, 0, 0, 0]);
          setOriginalNumResults([0, 0, 0, 0, 0]);
          setTrackers([0, 0, 0, 0, 0]);
          setCounter(0);
        };

        // Step 1
        let body1 = body;
        delete body1["product"]; 
        body1['q'] = queries[0];
        if (newQuestion[chatIndex]) {
          // INFO: follow up question, directly call /possible-answer
          setQueueDone(false);
          body1['user_replies'] = queries.slice(1, chatIndex+1); 
          body1['previous_responses'] = Object.values(fetchedDialog).slice(0, chatIndex);
          let body2 = {q: body.q, user_replies: queries.slice(1, chatIndex+1), sources: body1.sources};
          if (replacedQuery && replacedQuery !== '') {
            body2.q = replacedQuery;
            body1.q = replacedQuery;
          };
          requestPossibleAnswer(body2, body1, chatIndex);
        } else {
          // INFO: hard refresh on chat page + ask another question on chat page + search new question on search page + hard refresh on search page
          setQueries(queries => [q]);
        };

        if (location.pathname !== '/deep-ai-search') {
          // INFO: search new question on search page + hard refresh on search page
          setRequestTrackerContext(prev => { return {...prev, 'api/ask':true}});
          axios(config)
            .then((res) => {
              // setIsWaitingForRequest(false);
              setRequestTrackerContext(prev => { return {...prev, 'api/ask':false}});
              if (Object.hasOwn(res,"data") && Object.hasOwn(res.data,"results_info")) {
                if( Object.hasOwn(res.data.results_info,"replaced_query") ){
                  setReplacedQuery(res.data.results_info.replaced_query.text);
                }
                else{
                  setReplacedQuery(undefined);
                }
                // first check of extraction suggestions , then for suggested spelling
                if( Object.hasOwn(res,"data") && Object.hasOwn(res.data,"results_info") 
                    && Object.hasOwn(res.data.results_info,"extraction_suggestions") 
                    &&  typeof res.data.results_info.extraction_suggestions!== null ){
                  setFetchedQuery(res.data.results_info.extraction_suggestions);
                } else {
                  // first check of extraction suggestions , then for suggested spelling
                  if ( Object.hasOwn(res,"data") && Object.hasOwn(res.data,"results_info") 
                    && Object.hasOwn(res.data.results_info,"suggested_spelling") 
                    && res.data.results_info.suggested_spelling 
                    &&  typeof res.data.results_info.suggested_spelling!== null) {
                    setFetchedQuery(res.data.results_info.suggested_spelling);
                  } else {
                    setFetchedQuery(undefined);
                  };
                };
              };

              //send a log request when a search response is successfull
              if (mounted && send_logs && !skipRequests) {
                let log_body = {
                  ...body,
                  // "from-trending": is_trending,
                  "ask_response": res.data,
                  "get_filters_response": userContextFilters,//TODO make sure it is properly sync 
                  "selected_sources": selectedSourcesIDs,
                  "has_mobile_version": isSmallScreen,
                  "q": q,
                  // event: "search",
                };

                delete log_body["sources"]; 
                delete log_body["product"]; 
                delete log_body["search_params"]; 
                setAskResTemp(res.data);
                add_search_to_logs(log_body);
                timeOutSummary =  setTimeout(() => {
                  // setRequestUser(true);
                  requestGetSummary(); // ! this is a bug the function is cache with the hooks
                }, 150);
              };
              
              if (mounted && !skipRequests) {
                setCurrentSearchTerm(q); 
                searchContext.forceSearch = false;
                // if ask returns an empty array we have to display no results [] 
                if (res.data.results && res.data.results.length >= 0) {
                  if (!skipRequests) {
                    let resultsWithId = res.data.results.map((item, index) => ({ ...item, id: index + 1 }));
                    setFetchedResults(resultsWithId);
                  };
                  // Update filters only if user hasn't set any filters
                  if (!filtering) {
                    if (res.data.facets) {
                      const adapted_sources = [];
                      sources.forEach(source => adapted_sources.push({ name: source}));
                      setFetchedFilters(adapted_sources);
                    };
                  };
                  setTooManyRequestsError(false);
                } else {
                  // result are zero, landing can render no result
                  if (res.data.description && res.data.description.startsWith("Too many requests in the last 24 hrs, max number of requests is")) {
                    setTooManyRequestsError(res.data.description);
                  } else {
                    setTooManyRequestsError(false);
                  };
                };
                setMobileContext({ ...mobileContext,facetEntries:searchContext.facets , toggle: false });
                setIsLoading(false);
              };

              if (body && body.sources && body.sources !== ''
                  // translatedQuery true only if we are translating
                  && useTranslatedQuery && translatedQuery 
                  && Object.hasOwn(translatedQuery,'translation') && translatedQuery['translation'].trim() &&(
                  !Object.hasOwn(res,'data') || !Object.hasOwn(res.data,'results_info') 
                  || !Object.hasOwn(res.data.results_info,'replaced_query') 
                  || !Object.hasOwn(res.data.results_info.replaced_query,'text') 
                  || res.data.results_info.replaced_query.text === '')
                 ) {
                  // Step 2
                  let body2 = {q: q, sources: body1.sources};
                  body1.q = q;
                  requestPossibleAnswer(body2, body1, chatIndex);
                  setFetchedSuggestedQuestion(fetchedSuggestedQuestion => [...fetchedSuggestedQuestion, translatedQuery['translation']]); 

                  if (location.pathname === '/deep-ai-search') {
                    setLogInitialDialog(true);
                  };
                 }
              //////// Call suggest question here:
              else if(body && body.sources && body.sources !== ''  ) {
                const config1 = {
                  method: 'post',
                  url: `${window.BASE_URL_ASSISTED}suggest-question`,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  withCredentials: true,
                  data: JSON.stringify({q: Object.hasOwn(res, 'data')&& Object.hasOwn(res.data, 'results_info') &&
                  Object.hasOwn(res.data.results_info, 'replaced_query') && Object.hasOwn(res.data.results_info.replaced_query, 'text') 
                  && res.data.results_info.replaced_query.text !== '' ? res.data.results_info.replaced_query.text : q}),
                  signal: controller.signal,
                  cancelToken: source.token,
                };
                setQueueDone(false);
                axios(config1)
                  .then((res1) => {
                    if (res1.data.suggested_question && res1.data.suggested_question.length) {
                      // Step 2
                      let body2 = {q: q, sources: body1.sources};
                      body1.q = q;
                      if (Object.hasOwn(res,'data')&& Object.hasOwn(res.data,'results_info') 
                          && Object.hasOwn(res.data.results_info,'replaced_query') 
                          && res.data.results_info.replaced_query.text && res.data.results_info.replaced_query.text !== '') {
                        body2.q = res.data.results_info.replaced_query.text;
                        body1.q = res.data.results_info.replaced_query.text;
                      };
                      requestPossibleAnswer(body2, body1, chatIndex);
                      if (typeof fetchedSuggestedQuestion !== 'undefined' &&  fetchedSuggestedQuestion != null  && fetchedSuggestedQuestion.length >0 ){
                        setFetchedSuggestedQuestion(fetchedSuggestedQuestion => [...fetchedSuggestedQuestion, res1.data.suggested_question]); }
                      else{
                        setFetchedSuggestedQuestion([res1.data.suggested_question]);
                      }

                      if (location.pathname === '/deep-ai-search') {
                        setLogInitialDialog(true);
                      };
                    }
                    else{
                      setFetchedSuggestedQuestion(undefined); // undefined signals error to result-cards 
                    }        
                  })
                  .catch((error) => {
                    setFetchedSuggestedQuestion(undefined); // undefined signals error to result-cards 
                    console.log(error.message);
                  });
              // };
              }
              ////////////// Call suggest question here: ENDDDDD
          
              // regarless of result we need to reset
              setUseTranslatedQuery(false);
            })
            .catch((error) => {
              // setIsWaitingForRequest(false);
              setRequestTrackerContext(prev => { return {...prev, 'api/ask':false}});
              setTooManyRequestsError(false);
              // regarless of result we need to reset
              setUseTranslatedQuery(false);
              
              if (axios.isCancel(error)) {
                console.log('ask request canceled', error.message);
              } else {
                if (error && error.response && error.response.data.description && error.response.data.description.startsWith("Too many requests in the last 24 hrs, max number of requests is")) {
                  setTooManyRequestsError(error.response.data.description);
                } else {
                  setTooManyRequestsError(false);
                };
                push_empty_search();
              };
            });
          };
        }
      else{
        if( ((q !== '' && q !== currentSearchTerm) || searchContext.forceSearch === true) 
          && !skipRequests && Date.now() - lastUpdated < NINETEENMINUTES )
          {
            getTranslation(q);
            // getTranslation will get new query and retrigger this function (change searchContext)
          }
      }

        if (location.pathname !== '/deep-ai-search') {
          let urlParam = `?q=${q}`;
          urlParam = `?q=${q}&sources=${nameOfSources}`;
          urlParam = encodeURI(urlParam);
          let pathnameForSearch = '/search';
          ////TODO replace with getUser with hook and make sure it is properly sync 
          if (getUser() && userOrganisationContext &&userOrganisationContext["alias"]) {
            pathnameForSearch = '/'+userOrganisationContext["alias"]+'/search';
          };
          props.history.push({
            pathname: pathnameForSearch,
            search: urlParam,
          });
        };
      
      // Refresh on chat/search page TODO: when undefined? REAL REFRESH
      if ( typeof lastUpdated === 'undefined'|| Date.now()-lastUpdated >= NINETEENMINUTES) {
        setRequestTrackerContext(prev => { return {...prev, 'api/ask':true ,'get-summary':true}});
        setFetchedResults(undefined);
        if (searchContext.q) {
          if (countWords(searchContext.q) > 4) {
            setIsResultsExpanded(true);
            setResultsFirstExpanded(undefined);
          } else {
            setIsResultsExpanded(false);
            setResultsFirstExpanded(false);
          };
        };
     
        let res;
        if (isEnglishSpeaker &&(location.pathname !== '/deep-ai-search' || Date.now()-lastUpdated >= NINETEENMINUTES )) {
          res = askAsync(body, true);
        } else {
          res = askAsync(body, false); // ask is not call
          // because user is not english speaker tigger translate before ask
            if(!isEnglishSpeaker){
              getTranslation(body.q);
            }
        };

        res.then((value)=>{
          setRequestTrackerContext(prev => { return {...prev, 'api/ask':false ,'get-summary':false}});
          if(value &&value[0]){
            // add user
            if(typeof value[0]['num_searches'] !== undefined ){
              setUserStats({
                'num_searches': value[0]['num_searches'],
                'num_grouped_searches': value[0]['num_grouped_searches'],
                'num_bookmarks': value[0]['num_bookmarks'],
                'num_cpd_entries': value[0]['num_cpd_entries'],
                'unread_feedback': value[0]['unread_feedback'],
              });
            }
            if (value[0].user){
              // setUserSession(value[0].user);
              setUserContext(value[0].user);
              setLastUpdated(Date.now())
              // TODO: do not always triger get filters
              requestSources();
            }
          }
          if(value && value[1]){
            //add searchResults
            if(Object.hasOwn(value[1],"results_info")){
              if(value[1].results_info.replaced_query && 
                typeof value[1].results_info.replaced_query!== null ){
                  setReplacedQuery(value[1].results_info.replaced_query.text);
              }
              else{
                setReplacedQuery(undefined);
              }

              // first check of extraction suggestions , then for suggested spelling
              if( Object.hasOwn(value[1],"results_info") && Object.hasOwn(value[1],"extraction_suggestions") && 
                  value[1].results_info.extraction_suggestions &&  typeof value[1].results_info.extraction_suggestions!== null ){
                setFetchedQuery(value[1].results_info.extraction_suggestions);
              }
              else{
                // first check of extraction suggestions , then for suggested spelling
                if(Object.hasOwn(value[1],"results_info") && Object.hasOwn(value[1].results_info,"suggested_spelling") && 
                    value[1].results_info.suggested_spelling &&  typeof value[1].results_info.suggested_spelling!== null ){
                  setFetchedQuery(value[1].results_info.suggested_spelling);
                }
                else{
                  setFetchedQuery(undefined);
                }
              }
            }

            /////////////////////// call suggest question
            // Comment/Uncomment
            // if (!isMobile && userContext['dialog_allowed']) {
            // if (!isMobile) {
            // Step 1

            
            setQueueDone(false);
            let body1 = body;
            delete body1["product"];
            getSuggestedQuestion(res, body, body1, q);
            // axios({
            //   method: 'POST',
            //   url: `${window.BASE_URL_ASSISTED}suggest-question`,
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   withCredentials: true,
            //   data: JSON.stringify({q: res.data.results_info.replaced_query.text && res.data.results_info.replaced_query.text !== '' ? res.data.results_info.replaced_query.text : body.q}),
            // })
            //   .then((res1) => {
            //     if (res1.data.suggested_question && res1.data.suggested_question.length) {
            //       // Step 2
            //       let body2 = {q: body.q, sources: body1.sources};
            //       if (res.data.results_info.replaced_query.text && res.data.results_info.replaced_query.text !== '') {
            //         body2.q = res.data.results_info.replaced_query.text;
            //         body1.q = res.data.results_info.replaced_query.text;
            //       };
            //       requestPossibleAnswer(body2, body1, chatIndex);
            //       setQueries(queries => [...queries, q]);
            //       setFetchedSuggestedQuestion(fetchedSuggestedQuestion => [...fetchedSuggestedQuestion, res1.data.suggested_question]);

            //       if (location.pathname === '/deep-ai-search') {
            //         setLogInitialDialog(true);
            //       };
            //     };
            //   });


            // };
            /////////////////////// call suggest question END

            let log_body = {
              ...body,
              "ask_response": value[1],
              "get_filters_response": userContextFilters,//TODO make sure it is properly sync 
              "selected_sources": selectedSourcesIDs,
              "has_mobile_version": isSmallScreen,
            };
            delete log_body["sources"]; 
            delete log_body["product"]; 
            delete log_body["search_params"]; 
            setAskResTemp(value[1]);
            add_search_to_logs(log_body);
            timeOutSummary =  setTimeout(() => {
              // setRequestUser(true);
              requestGetSummary(); // ! this is a bug the function is cache with the hooks
            }, 150);
            setCurrentSearchTerm(q); 
            searchContext.forceSearch = false;
            // if ask returns an empty array we have to display no results [] 
            if ( value[1].results && value[1].results.length>=0) {
              let resultsWithId = value[1].results.map((item, index) => ({ ...item, id: index + 1 }));
              setFetchedResults(resultsWithId);
              // Update filters only if user hasn't set any filters
              if (!filtering) {
                if(value[1].facets){
                const adapted_sources = [];
                sources.forEach(source => adapted_sources.push({ name: source}));
                setFetchedFilters(adapted_sources);
                }
              }
              setTooManyRequestsError(false);
            } else {
              // result are zero, landing can render no result
              if(value[1].description && value[1].description.startsWith("Too many requests in the last 24 hrs, max number of requests is") ){
                setTooManyRequestsError(value[1].description);
              }
              else{
                setTooManyRequestsError(false);
              }
            }
            // setMobileContext({ ...mobileContext, toggle: false });
            setIsLoading(false);
            if (location.pathname !== '/deep-ai-search') {
              let urlParam = `?q=${q}`;
              urlParam =`?q=${q}&sources=${nameOfSources}`;
              urlParam = encodeURI(urlParam);
              let pathnameForSearch = '/search';
              //TODO make sure it is properly sync 
              if( getUser() && userOrganisationContext &&userOrganisationContext["alias"]){
                pathnameForSearch = '/'+userOrganisationContext["alias"]+'/search';
              }
              props.history.push({
                pathname: pathnameForSearch,
                search: urlParam,
              });
            }
          };
          
          // if (value && value[2]) {
          //   if (value[2].suggested_question && value[2].suggested_question.length > 0) {
          //     setQueries(queries => [...queries, q]);
          //     setFetchedSuggestedQuestion(fetchedSuggestedQuestion => [...fetchedSuggestedQuestion, value[2].suggested_question]);
          //   };
          // }
        })
        .catch((error) => {
          // setIsWaitingForRequest(false);
          setRequestTrackerContext(prev => { return {...prev, 'api/ask':false ,'get-summary':false}});
          setTooManyRequestsError(false);
          
          if (axios.isCancel(error)) {
            console.log('ask request canceled', error.message);
          } else {
            console.log('ask request canceled', error);
            console.log('askerror', error);

            if(error && error.response && error.response.data.description && error.response.data.description.startsWith("Too many requests in the last 24 hrs, max number of requests is") ){
              setTooManyRequestsError(error.response.data.description );
            }
            else{
              setTooManyRequestsError(false);
            }
            push_empty_search();
          }
        });

        // above here
      };
    };
    
    return function cleanup() {
      mounted = false; // TODO remove 
      filtering = false;
      clearTimeout(timeOutSummary);
      clearInterval(timeOutGetAnswser);
      cancelRequest(' cancelling ask request');
    };
    // eslint-disable-next-line
  }, [props.history, searchContext, userId]);
  // , newQuestion, chatContext



  useEffect(() => {
    if (userOrganisationContext && userOrganisationContext['assisted_ai_web_usage'] === false) {
      setAssistedAiAllowWebUsage(false);
    };
    if (userOrganisationContext && userOrganisationContext['assisted_ai_autoexpand_word_count']) {
      setAssistedAiAutoexpandWordCount(userOrganisationContext['assisted_ai_autoexpand_word_count']);
    };
    if (userOrganisationContext && userOrganisationContext['extracted_ai_enabled'] === false) {
      setShowAssistedAIFromStart(false);
    };
  }, [userOrganisationContext]);

  useEffect(() => {
    if (changeWeb) {
      let urlParam = `?q=${queries[0]}&sources=Web`;
      urlParam = encodeURI(urlParam);
      let pathnameForSearch = '/deep-ai-search';

      props.history.push({
        pathname: pathnameForSearch,
        search: urlParam,
      });

      window.location.reload();
    }
  }, [changeWeb]);

  useEffect(() => {
    if (counter >= 2 || resultAnswerError) {
      let selectedSourcesIDs = [];
      let facets = searchContext.facets;
      if ((!is_single_source) && facets && facets.length !== 0) {
        searchContext.facets.forEach(source => {
          // if it is a src object 
          if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
          //check source is valid before sending a request
            if(source.hasOwnProperty('id')){
              selectedSourcesIDs.push(source['id']);
            };
          }
          // else it is not a src but an display filter add array of sources
          else {
            if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
              source['sources'].forEach(source => {
                if(source.hasOwnProperty('id')){
                  selectedSourcesIDs.push(source['id']);
                }
              });
            }
          }
        });
      };

      let index;
      if (loadMore) 
        index = activeChatIndex;
      else 
        index = chatIndex;

      if (resultAnswerError) {
        const updatedFetchResults = [...fetchResults];
        updatedFetchResults[index] = false;
        setFetchResults(updatedFetchResults);

        const updatedTrackers = [...trackers];
        updatedTrackers[index] = counter;
        setTrackers(updatedTrackers);

        setOriginalNumResults(numMachineResults);
        setActiveChatIndex(undefined);

        const updatedFetchAnswers = [...fetchAnswers];
        updatedFetchAnswers[index] = false;
        setFetchAnswers(updatedFetchAnswers);

        const system_msg = "An error has occurred. We are looking into it. Please try again in a few minutes.";
        setFetchedDialog(fetchedDialog => ({...fetchedDialog, [index]: [...fetchedDialog[index], system_msg]})); 

        if (location.pathname != '/deep-ai-search') {
          setLoadMore(false);
        };

        // No composed answer
        let log_body = {
          "results": {
            "machine_reply_results": machineResults[index],
            "composed_answer": system_msg,
          },
          "search_num": searchNum
        };

        if (loadMore) {
          log_body.dialog_id = dialogIDs[index];
        } else {
          log_body.q = queries[index];
          log_body.get_filters_response = userContextFilters;//TODO make sure it is properly sync 
          log_body.selected_sources = selectedSourcesIDs;
          log_body.has_mobile_version = false;
          log_body.results.possible_answer = possibleAnswer[index];
          if (index === 0) {
            log_body.suggested_question = fetchedSuggestedQuestion.at(0);
            log_body.dialog_id = dialogIDs[index];
            log_body.first_loaded = true;
          };
        };
      
        if (location.pathname === '/deep-ai-search') {
          // Final log dialog
          add_dialog_to_logs(log_body, index, true, false);
        } else {
          setNeedSeparateLogDialog(true);
          setSeparateLogDialogPayload({log_body, index});
        };
        setResultAnswerError(false);  
      } else {
        var body3 = {
          q: queries[0] ? queries[0] : searchContext.q,
          search_params: userContext['search_params'],
          sources: getResultSources(),
        };
  
        if (newQuestion[chatIndex]) {
          body3['user_replies'] = queries.slice(1, chatIndex+1); 
          body3['previous_responses'] = Object.values(fetchedDialog).slice(0, chatIndex);
        };
  
        if (counter >= trackers[index]+2) {
          if (counter < trackers[index]+10 && Object.keys(machineResults[index]).length + Object.keys(webResults[index]).length < originalNumResults[index]+2) {
            // After the initial two calls, before it reaches the 10th call, get less than two new results => keep calling
            getAnswer(body3, counter+1, index, true);
          } else if (location.pathname !== '/deep-ai-search' && assistedAiAllowWebUsage && !loadMore && counter < trackers[index]+20 && (Object.keys(machineResults[index]).length + Object.keys(webResults[index]).length) < originalNumResults[index]+2) {
            // Do extra 10 searches using the Web source
            getAnswer(body3, counter+1, index, true);
          } else if (Object.keys(machineResults[index]).length + Object.keys(webResults[index]).length === originalNumResults[index]+2 || counter === trackers[index]+10 || counter === trackers[index]+20) {
            // Get two new results OR it failed to get two within 10 trials (for deep AI search) or 20 trials (for assisted AI search)
            const updatedFetchResults = [...fetchResults];
            updatedFetchResults[index] = false;
            setFetchResults(updatedFetchResults);
    
            const updatedTrackers = [...trackers];
            updatedTrackers[index] = counter;
            setTrackers(updatedTrackers);
    
            setOriginalNumResults(numMachineResults);
            setActiveChatIndex(undefined);

            if (location.pathname != '/deep-ai-search') {
              setLoadMore(false);
            };
    
            // Step 4
            if (Object.keys(machineResults[index]).length > originalNumResults[index]) {
              // Get one or two results
              body3.tracker = counter;
              if (location.pathname === '/deep-ai-search') {
                requestDialog(body3, selectedSourcesIDs, index, false); 
              } else {
                setNeedSeparateComposeAnswer(true);
                setSeparateComposeAnswerPayload({
                  body: body3, // TODO: search param might expire
                  sourcesId: selectedSourcesIDs,
                  index: index,
                });
              };
            } else {
              // Get no new results
              if (location.pathname !== '/deep-ai-search') {
                setProgress(100);
              };
              
              const updatedFetchAnswers = [...fetchAnswers];
              updatedFetchAnswers[index] = false;
              setFetchAnswers(updatedFetchAnswers);
  
              const system_msg = "Nothing was found. Consider changing sources e.g. <span class='web-change1'>whole Web</span> or click <span class='load-more1'>load more</span> to keep searching.";
              setFetchedDialog(fetchedDialog => ({...fetchedDialog, [index]: [...fetchedDialog[index], system_msg]})); 
  
              // No composed answer
              let log_body = {
                "results": {
                  "machine_reply_results": machineResults[index],
                  "composed_answer": "Nothing was found. Consider changing sources e.g. whole Web or click load more to keep searching.",
                },
                "search_num": searchNum
              };
    
              if (loadMore) {
                log_body.dialog_id = dialogIDs[index];
              } else {
                log_body.q = queries[index];
                log_body.get_filters_response = userContextFilters;//TODO make sure it is properly sync 
                log_body.selected_sources = selectedSourcesIDs;
                log_body.has_mobile_version = false;
                log_body.results.possible_answer = possibleAnswer[index];
                if (index === 0) {
                  log_body.suggested_question = fetchedSuggestedQuestion.at(0);
                  log_body.dialog_id = dialogIDs[index];
                  log_body.first_loaded = true;
                };            
              };
            
              if (location.pathname === '/deep-ai-search') {
                // Final log dialog
                add_dialog_to_logs(log_body, index, true, false);
              } else {
                setNeedSeparateLogDialog(true);
                setSeparateLogDialogPayload({log_body, index});
              };
            };
          };
        };
      };
    };
  }, [counter, resultAnswerError]);

  useEffect(() => {
    setCounter(0);
  }, [chatIndex]);

  useEffect(() => {
    if (activeChatIndex !== undefined && loadMore) {  
      let body = {
        q: queries[0], 
        sources: getResultSources(),
        search_params: userContext['search_params'],
      };
      if (newQuestion[activeChatIndex]) {
        body['user_replies'] = queries.slice(1, activeChatIndex+1); 
        body['previous_responses'] = Object.values(fetchedDialog).slice(0, activeChatIndex);
      }
      // Step 3
      const updatedFetchResults = [...fetchResults];
      updatedFetchResults[activeChatIndex] = true;
      setFetchResults(updatedFetchResults);
      const updatedFetchAnswers = [...fetchAnswers];
      updatedFetchAnswers[activeChatIndex] = true;
      setFetchAnswers(updatedFetchAnswers);
      setCounter(trackers[activeChatIndex]);
      getAnswer(body, trackers[activeChatIndex]+1, activeChatIndex, true);
      getAnswer(body, trackers[activeChatIndex]+2, activeChatIndex, false);
    };
  }, [activeChatIndex, loadMore]);
   
  useEffect(() => {
    if(typeof currentOrgName ==="undefined" && typeof userContext['org_name'] !== "undefined"){
      requestOrgInfo();
    }
    else {
      if (userContext !== undefined && userContext['org_name'] !== undefined && userContext['org_name'] !== currentOrgName) {
        requestOrgInfo();
      }
    }
  }, [userContext['org_name']]);

  useEffect(() => {
    if(requestUser){
      requestGetSummary();
      setRequestUser(false);
    }
  }, [requestUser]);

  // TO DO:  change timer with async function when user clicks or press enters
  //  !! this requires checking for expiry everytime searchParam is use
  useEffect (()=>{
    let timer;
    if(typeof lastUpdated=== "undefined"){
      requestGetSummary();
    }
    else{
      timer =setTimeout(()=>{
        window.addEventListener('pointermove', handleUpdate);
        window.addEventListener('keydown', handleUpdate);
        window.addEventListener('click', handleUpdate);
      },NINETEENMINUTES);
    }
    
    return ()=>{
      window.removeEventListener('pointermove', handleUpdate);
      window.removeEventListener('keydown', handleUpdate);
      window.removeEventListener('click', handleUpdate);
      clearTimeout(timer);
    }
},[lastUpdated])

  useEffect(() => {
    if(requestFilters){
      requestSources();
      setRequestFilters(prev=>false);
    }
  }, [requestFilters]);


  return (
    <AppContext.Provider
      value={{
        searchContext,
        setSearchContext,
        chatContext,
        setChatContext,
        goToChatPage,
        setGoToChatPage,
        fetchedResults,
        setFetchedResults,
        chatIndex,
        setChatIndex,
        fetchedSuggestedQuestion,
        setFetchedSuggestedQuestion,
        fetchResults,
        setFetchResults,
        possibleAnswer,
        setPossibleAnswer,
        machineResults,
        setMachineResults,
        numMachineResults,
        setNumMachineResults,
        webResults,
        setWebResults,
        localWebResults,
        setLocalWebResults,
        clearLocalWebResults,
        setClearLocalWebResults,
        counter,
        setCounter,
        loadMore,
        setLoadMore,
        newQuestion,
        setNewQuestion,
        queries,
        setQueries,
        trackers,
        setTrackers,
        fetchedDialog,
        setFetchedDialog,
        mobileContext,
        setMobileContext,
        feedbackContext,
        setFeedbackContext,
        reportIssue,
        setReportIssue,
        userContext,
        setUserContext,
        removeUserContextFilters,
        userOrganisationContext,
        setUserOrganisationContext,
        removeUserOrganisationContext,
        assistedAiAutoexpandWordCount,
        requestTrackerContext,
        setRequestTrackerContext,
        showCovidSwitch,
        setShowCovidSwitch,
        exampleCarouselCards,
        setExampleCarouselCards,
        parsedCarouselCards,
        setParsedCarouselCards,
        userId,
        setUserId,
        userStats,
        setUserStats,
        queueDone,
        setQueueDone,
        waitForNextAnswer,
        setWaitForNextAnswer,
        fetchedAdvancedSuggestions,
        setFetchedAdvancedSuggestions,
        progress,
        setProgress,
        isResultsExpanded,
        setIsResultsExpanded,
        resultsFirstExpanded,
        setResultsFirstExpanded,
        confirmCouldNotFind, 
        setConfirmCouldNotFind,
        suspectCouldNotFind, 
        setSuspectCouldNotFind,
        showAssistedAI,
        setShowAssistedAI,
        showAssistedAIFromStart,
        setShowAssistedAIFromStart,
        fetchDialog,
        fetchAnswers,
        chatSources,
        fetchedQuery,
        replacedQuery,
        fetchedFilters,
        fetchedMoreLikeThis,
        userContextFilters,
        isLoading,
        lastUpdated,
        tooManyRequestsError,
        setChangeWeb,
        setOriginalNumResults,
        setActiveChatIndex,
        setRequestFilters,
        setSkipRequests, 
        setRequestUser,
        setAnonQuery,
        anonQuery,
        assistedAiAllowWebUsage
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const RouterAppProvider = withRouter(AppProvider);

export { RouterAppProvider, AppContext };
