import axios from 'axios';
import { getAllSources, getUser } from './manageSessions';
import DOMPurify from 'dompurify';

const resources = {};

const makeRequestCreator = () => {
  let cancel;
  return async (query) => {
    let allSourcesObj= getAllSources();
    let allSources = '';

    allSourcesObj.forEach(element => {
      if (element['encrypted_url']){
        allSources = allSources + ',' +element['encrypted_url'] ;
      }
    });
    allSources = allSources.substring(1);
    if (cancel) {
      // Cancelxax the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      // if (resources[query]) {
      //   console.log('resources',resources[query]);
      //   // Return result if it exists
      //   return resources[query];
      // }
      let tempUrl =query.split('?')[0];
      let tempQ =query.split('q=')[1].trim(); 
      console.log('tempQ',tempQ);
      console.log('sources',allSources);

      const res = await axios({
        method: 'post',
        url:tempUrl,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          q: tempQ,
          sources: allSources,
        }),
      });
      // const res = await axios(query ,{ withCredentials:true, cancelToken: cancel.token, data: JSON.stringify({
      //   sources: 'red',
      // }) });
      const result = res.data;
      // Store response
      resources[query] = result;

      return result;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
        console.log('suggest request canceled', error.message);
      } else {
        // Handle usual errors
        console.log('Something went wrong: ', error.message);
      }
    }
  };
};

const makeRequestUserAndAsk = ( body, callAsk ) => {
  let cancel;
  return async (body, callAsk) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      const res = await axios({
        method: 'post',
        url:window.BASE_URL_USER+'get-summary',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (res.data.user['search_params'] && callAsk) {
        let tempBody = {...body,
          search_params: res.data.user['search_params'],
        }
       
        const config = {
          method: 'post',
          url: `${window.BASE_URL_ASK}api/ask`,
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          data: JSON.stringify(tempBody),
        };
        const resAsk = await axios(config);
        
        return [res.data, resAsk.data];
      }
      return [res.data];
    }
    
    catch{
      console.log("get-summary and ask");
    }
  };
};

const makeRequestUser = ( ) => {
  let cancel;
  return async (body) => {
    if (cancel) {
      // Cancelxax the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      const res = await axios({
        method: 'post',
        url:window.BASE_URL_USER+'get-summary',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.data;
    }
    catch{
      console.log("get-summary");
    }
  };
}; 

// export const requestUser = makeRequestUser();
export const search = makeRequestCreator();
export const search2 = makeRequestCreator();
export const askAsync = makeRequestUserAndAsk();

export const userAsync = makeRequestUser();



export const stripQuestionmark = (val) => {
  if (val) return val.replace(/[?]/gi, '');
  else return val;
};

export const stripTagsArray =  (array) =>{
  var output=[];
  if (array){
    array.forEach(element => {
      output.push(stripTags(element));
    });
  };
  return output; 
}

export const removeDuplicatesFromSecondArray = (array1, array2) => {
  let mapTemp = new Map();
  array1.forEach(element => {
    let cleanElement =  element.toLowerCase().trim();
    cleanElement = stripTags(cleanElement).trim();
    if  ( element && cleanElement && typeof element === "string" && !mapTemp.has( cleanElement )){
      mapTemp.set(cleanElement, element );
    }
  });
  // let out2 = Array.from(array2, x=>mapTemp[stripTags(x.toLowerCase().trim())]);
  let out2 = array2.filter(x => !mapTemp.has(stripTags(x.toLowerCase().trim())) );
  return out2;
}

export const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export const stripTags = (val) => {
  if ( val && typeof val === "string" && val !== "") return val.replace(/(<([^>]+)>)/gi, '');
  else return val;
};

export const isUrlPdf = (url) => {
  if (typeof url === 'string') {
    let extension = url.split(/[#?]/)[0].split('.').pop().trim();
    if (extension === 'pdf') return true;
  }
  return false;
};

export const passwordStrengthChecker = (pass) => {
  let strength;

  if (pass.length >= 8 && pass.length <= 32) {
    if (
      pass.match(/[a-z]/) ||
      pass.match(/\d+/) ||
      pass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    )
      strength = 'Weak';

    if (
      (pass.match(/[a-z]/) && pass.match(/\d+/)) ||
      (pass.match(/\d+/) && pass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) ||
      (pass.match(/[a-z]/) && pass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))
    )
      strength = 'Medium';

    if (
      pass.match(/[a-z]/) &&
      pass.match(/\d+/) &&
      pass.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    )
      strength = 'Strong';
    return strength;
  } else {
    return null;
  }
};

export const get_formated_num = (int0) => {
  let factor = " "; 
  if (int0 >= 1000000){
    int0 = int0/1000000;
    if (int0 > 1 ){ factor = " millions";}
    else{factor = " million";}
   
  }
  if(int0 >= 1000 && int0 < 1000000){
    int0 = int0/1000
    factor = " k"
  }
  return int0 + factor;
};

// clean P tags that only have one word by replacing them 
// with their corresponding text AND remove all <img> tags
// input: a html as a string 
// output: a string representing html 
export const removeUnecessaryPTags = (results) => {

  DOMPurify.addHook('afterSanitizeElements', function (node) {
    // console.log(node);
    if (node.nodeName && node.nodeName === 'P' ) {
      // node.textContent = node.textContent.toUpperCase();
      if (node.textContent.trim().split(' ').length <= 1 && node.parentNode) {
        node.insertAdjacentHTML('afterend', node.textContent);
        node.remove();
      }

    }
  });
   let out = DOMPurify.sanitize(results, {FORBID_TAGS: ['img']});
  return out;
};

export const getUserAvatarInitials = () => {
  // console.log('***getting initials');
  // console.time()
  const user_info = getUser();
  let initials = '';
  if ( user_info ) {
    if ( user_info["first-names"] &&  user_info["first-names"] !== ''){
      initials = initials + user_info["first-names"].charAt(0).toUpperCase();
    }
    if (user_info["last-names"] &&  user_info["last-names"] !== ''){
      initials  = initials +  user_info["last-names"].charAt(0).toUpperCase();
    }
  }
  else {
    initials = '??';
  }
  if (initials.length <2)
  {
    initials = initials +'?';
  }
  // console.timeEnd();
  // console.log('getting initials ****');
  return initials;
};

export const getUserAvatarFullName = () => {
  const user_info = getUser();
  let fullName = 'Registered';
  if ( user_info ) {
    if ( user_info["first-names"] && user_info["first-names"] !== '' && user_info["last-names"] && user_info["last-names"] !== ''){
      fullName = user_info["first-names"] + " " + user_info["last-names"];
    } else if (user_info["first-names"] &&  user_info["first-names"] !== ''){
      fullName = user_info["first-names"];
    } else if (user_info["last-names"] &&  user_info["last-names"] !== ''){
      fullName = user_info["last-names"];
    };
  }
  else {
    fullName = '??';
  };
  return fullName;
};

export const getUserAvatarOrgAlias = (org_data) => {
  // const org_data = getOrgData();
  let orgAlias = 'Magic User';
  if ( org_data ) {
    if ( org_data["alias"] && org_data["alias"] !== ''){
      orgAlias = org_data["alias"];
    };
  };
  return orgAlias;
};

export const getUserAvatarExtraInfo = () => {
  const user_info = getUser();
  let extraInfo = '?';
  if ( user_info ) {
    if ( user_info["location"] && user_info["location"] !== ''){
      let truncated_location = user_info['location'].substring(0,32);
      if (truncated_location.length !== user_info['location'].length ) {
        extraInfo =user_info['location'].substring(0,32)+ '...';
      } else {
        extraInfo =user_info['location'];
      };
      // extraInfo = user_info["location"];// truncate 
    } else if (user_info["profession"] &&  user_info["profession"] !== ''){
      let truncated_profession =user_info['profession'].substring(0,32);
      if (truncated_profession.length !== user_info['profession'].length ) {
        extraInfo =user_info['profession'].substring(0,32)+ '...';
      } else {
        extraInfo =user_info['profession'];
      };
    };
  }
  else {
    extraInfo = '??';
  };
  return extraInfo;
};

export const goToDashboard = () => {
  const user_info = getUser();
  if (user_info) {
      if (user_info['org-user-type']==="admin"){
          window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/org_summary.html');
      } else if (user_info['org-user-type']==="magic") {
        window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/users_feedback.html');
      } else {
        window.location.replace(window.BASE_URL_DASHBOARD+'dashboard');
      }
  } else {
      // window.location.replace(window.BASE_URL_DASHBOARD+'login');
      window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/not_logged_in.html');
  }
};

//decode name of sources
export const getNameOfDecoded= (encodedName) =>{
  var parser = new DOMParser();
  var decodedPublisherName = parser.parseFromString(encodedName, 'text/html').body.textContent;
  return decodedPublisherName; 
}

export const parseNameOfFilters = (name) => {
  if(name && name !== "" && name !== 'Unknown' ){
    var parser = new DOMParser();
    var decodedPublisherName = parser.parseFromString(name, 'text/html').body.textContent;
    if(decodedPublisherName){
      return decodedPublisherName;
    }
    else{
      return name;
    }
  }
};

export const countWords = (str) => {
  return str.trim().split(/\s+/).length;
};