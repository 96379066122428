import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './components/theme/global';
import ScrollToTop from './functions/scrollToTop';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RouterAppProvider } from './context';
import PublicRoute from './functions/PublicRoute';
import PrivateRoute from './functions/PrivateRoute';
import OrgRoute from './functions/OrgRoute';
import LandingPage from './pages/landing-page';
import SearchRoute from './functions/SearchRoute';
import SearchResultsPage from './pages/search-results-page';
import ChatPage from './pages/chat-page';
import SharedContentPage from './pages/shared-content-page';
import LoginPage from './pages/login-page';
import LoginPageIntegrated from './pages/login-page-integrated';
import LoginPageAfterSignup from './pages/login-page-after-signup';
import UnconfirmedAccountPage from './pages/unconfirmed-account-page';
import PasswordResetPage from './pages/password-reset-page';
import SignUpPage from './pages/signup-page';
import AccountPage from './pages/account-page';
import EditorPage from './pages/editor-page';
import ErrorPage from './pages/error-page';
import LandingRoute from './functions/LandingRoute';
import PrivateLandingRoute from './functions/PrivateLandingRoute';
// import 'bootstrap/dist/css/bootstrap.min.css'; breaks some styles when imported

import { theme } from './components/theme/variables';
// Below service worker can be found in src/_not-used_ folder. More info in the end of this page.
// import * as serviceWorker from './serviceWorker';

import { parseUri } from './functions/parseUri';
import { Redirect } from "react-router-dom";
import LoadingPage from './pages/loading-page';
import LoginMagicPage from './pages/login-magic-page';
import { A } from './components/theme/typography';
import SignUpPageSoarBeyond from './pages/signup-page-soar-beyond';
import Hotjar from '@hotjar/browser';



const reload = () => window.location.reload();

let basename_path = null;
var parsed = parseUri(window.location.pathname.toLowerCase());

const DEV_NAME = 'cairo';


// if noneof the cases is resolve we do need the variables to be decleare  
window.BASE_URL_ASK ='https://ask.medwise.ai/';
window.BASE_URL_ASSISTED ='https://assisted.medwise.ai/';
window.BASE_URL_USER ='https://users.medwise.ai/';
window.BASE_URL_DASHBOARD='/';
window.BASE_URL_DOCUMENTS='/';
window.BASE_URL_APP ='/';
window.BASE_API_URL='/';



let siteId = 3572806; // siteId for medwise.ai

// remember https is needed else https redirrection will 
// double the link an not enpoint or link will work

if (window.location.hostname == 'medwise.ai'){
  
  window.BASE_URL_ASK ='https://ask.medwise.ai/';
  window.BASE_URL_USER ='https://users.medwise.ai/';
  window.BASE_URL_ASSISTED ='https://assisted.medwise.ai/';
  window.BASE_URL_DASHBOARD='https://medwise.ai/';
  window.BASE_URL_DOCUMENTS='https://documents.medwise.ai/';
} else {
  // local host can not use https
  if (window.location.hostname === 'localhost' ){
    window.BASE_URL_ASK ='http://localhost:8009/';
    window.BASE_URL_USER ='http://localhost:8005/';
    window.BASE_URL_ASSISTED ='http://localhost:8009/'; // we can use same as ask bc it is the same container img 
    window.BASE_URL_DASHBOARD='https://'+DEV_NAME+'.medwise.ai/';
    window.BASE_URL_DOCUMENTS ='http://localhost:8007/';
  }
  // remember https is needed else redirrection will 
  // double the link an not enpoint or link will work
  if (window.location.hostname === DEV_NAME+'.medwise.ai'){
    siteId = 3569206; //  hotjar id for cairo/dev
    window.BASE_URL_ASK ='https://ask.'+DEV_NAME+'.medwise.ai/';
    window.BASE_URL_USER ='https://users.'+DEV_NAME+'.medwise.ai/';
    window.BASE_URL_ASSISTED ='https://assisted.'+DEV_NAME+'.medwise.ai/';
    window.BASE_URL_DASHBOARD='https://'+DEV_NAME+'.medwise.ai/';
    window.BASE_URL_DOCUMENTS ='https://documents.'+DEV_NAME+'.medwise.ai/';
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-NG58Z7W', 
  dataLayer:{
    userProject: 'main'
  }
};
TagManager.initialize(tagManagerArgs);

const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

if (parsed.path.indexOf('wise') === -1) {
  // If string(wise) not available
  basename_path = '/';
  window.extra_path = '';
} else {

  var match = parsed.path.match('(/wise/.+?/)');
  basename_path = match[1];

  if (basename_path.slice(-1) !== '/') {
    window.extra_path = basename_path.slice(1) + '/';
  } else {
    window.extra_path = basename_path.slice(1, -1) + '/';
  }
  window.BASE_API_URL = window.BASE_API_URL+ window.extra_path;
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={basename_path}>
      <RouterAppProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ScrollToTop>
            <Switch>
              <LandingRoute exact path="/" component={LandingPage} />
              <SearchRoute path="/search" component={SearchResultsPage} />
              <Route path="/deep-ai-search" component={ChatPage} />
              <Route path="/magic" component={LoginMagicPage} />

              <Route path="/loading" component={LoadingPage} />
              <Route path="/shared-content/:content_id" component={SharedContentPage} />
              <PublicRoute path="/login" component={LoginPageIntegrated} />
              <PublicRoute path="/login_signup_successful" component={LoginPageAfterSignup} />
              <Route path="/unconfirmed" component={UnconfirmedAccountPage} />
              <PublicRoute
                path="/passwordreset"
                component={PasswordResetPage}
              />
              <PublicRoute path="/signup" component={SignUpPage} />
              <PublicRoute path="/signupSoarBeyond" component={SignUpPageSoarBeyond} />
              <PrivateRoute path="/account" component={AccountPage} />

              <Route path="/terms.html" render={reload} />
              <Route path="/terms" render={() => <Redirect
                to={{ pathname: "/terms.html" }}
              />}
              />
              <Route path="/about.html" render={reload} />              
              <Route path="/about" component={()=> {
              window.location.href = 'http://about.medwise.ai/';
              return null;
             }} />
              

              <Route path="/privacy.html" render={reload} />
              <Route path="/privacy" render={() => <Redirect
                to={{pathname: "/privacy.html" }}
              />}/>

              <Route path="/accessibility.html" render={reload} />
              <Route path="/accessibility.html" render={() => <Redirect
                to={{pathname: "/accessibility.html" }}
              />}/>
              
              
              <Route path="/contact" render={() => <p>Contact</p>} />
              <Route path="/editor" component={EditorPage} />
              {/*<Route path="/about" render={() => <p>About</p>} />*/}
              <Route path="/blog" render={() => <p>Blog</p>} />             
              <OrgRoute  path="/:alias/search" component={SearchResultsPage}/>
              <OrgRoute  path="/:alias/chat" component={ChatPage}/>
              <OrgRoute  path={"/:alias" } component={LandingPage}/>
              {/* <Route component={LandingPage} exact path={[ "/:id/account"]} /> */}

              <Route component={ErrorPage} />
            </Switch>
          </ScrollToTop>
        </ThemeProvider>
      </RouterAppProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
