import React, {useContext, useEffect, useState} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';
import {
  SuggestedAnswer,
  MachinResultsContainer,
  GeneratedResultsContainer,
  LineClamp,
  } from '../styles'; // we use the style sheet from results cards
import { Button, Col, Container,Row } from 'react-bootstrap';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';
import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import Hr from '../../hr/index.js';
import { H2,P,Label,A } from '../../theme/typography.js';
import PublisherTag from '../../publisher-tag';
// import ResultCard from '../result-card/index.js';
import UniversalCard from '../universal-card/index.js';



const GeneratedAnswer = (props) => {
    const {
        fetchedResults,
        fetchedSuggestedQuestion,
        progress,
        machineResults,
        localWebResults,
        isResultsExpanded,
        setIsResultsExpanded,
        fetchResults,
        trackers,
        setActiveChatIndex,
        loadMore,
        setLoadMore,
        setQueueDone,
        clearLocalWebResults,
        setClearLocalWebResults,
        assistedAiAllowWebUsage,
        numMachineResults,
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [refencesExpanded, setRefencesExpanded] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(true);
  const [answerProgress, setAnswerProgress] =useState(80 );
  const [responseText, setResponseText] =useState("");
  const ONESECOND = 8000;

  function changeProgress (){
    setAnswerProgress(100);
  };

  function setText (){
    setResponseText("The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points:The uploaded documents provide comprehensive information on the risk factors, diagnosis, and complications associated with both type 1 and type 2 diabetes. Here are the key points: ") ;
  };

  useEffect(() => {
    let interval ;
    let interval2 ;
    if (answerProgress <100){
      interval2 = setTimeout(
        setText
      , 4000);
      interval = setTimeout(
        changeProgress
      , ONESECOND);
      }

    return () => {
      if (interval){
        clearTimeout(interval);
        clearTimeout(interval2);
      }

    }
  },[]);


  function toggleAIResults() {
    // zero results and loading dont open
    // any results open
    // zero result and finishedloading OPEN
    // if( ( machineResults && typeof Object.values(machineResults)[0] === 'undefined' )
    //     || ( machineResults && Object.values(machineResults)[0].length <= 0 && ! finishedLoading)
    //  ){
    //   // setContainerIsExpanded(false);
    //   console.log("click excluded nothing");
    // }

    // // no unexpanding if  fetchedSUggestedQuestion is not found
    // else if ( typeof fetchedSuggestedQuestion !== 'undefined' && fetchedSuggestedQuestion.length > 0
    // && fetchedSuggestedQuestion[0]){
    //   setContainerIsExpanded(!containerIsExpanded);
    // }
    if(responseText !== ''){
      setContainerIsExpanded(!containerIsExpanded);
    }

  }
  function expandReferences(){
    setRefencesExpanded(prev => {return !prev});
  };


  const ExpandButton =  props => {
    return(
      <Button
      size="sm"
      className="progress-button"
      style={{
        cursor: answerProgress >= 100 ? 'default' : 'pointer',
      }}
      >
      {containerIsExpanded  ?  (
        <FaChevronUp color="#fff"/>
      ) : (
        <FaChevronDown color="#fff" />
      )}
      </Button>
    );
  }

  useEffect(() => {
    if(containerIsExpanded !==  isResultsExpanded && typeof containerIsExpanded !== 'undefined' && typeof isResultsExpanded !== 'undefined'){
      setIsResultsExpanded(containerIsExpanded);
    }
  }, [containerIsExpanded]);

  // useEffect(() => {
  //   setContainerIsExpanded(false);
  // }, [fetchedSuggestedQuestion]);

  // localWebResults as a source are only sent last if no other get-answer-from-results request provided a result
  // if we have localWebResults we are not sending anymore get-answer-from-results resquest
  // useEffect(() => {
  //   if(localWebResults && Object.values(localWebResults)[0] && Object.values(localWebResults)[0].length){
  //     // if we have web result we have finish all the call to get-answer-from-result
  //     setFinishedLoading(true) ;
  //   }
  //   else{
  //     // if()
  //     if(numMachineResults && numMachineResults[0] === 2 ){
  //       setFinishedLoading(true);
  //     }
  //     else if (!fetchResults[0] && loadMore === false &&
  //       !Object.values(localWebResults)[0].length ){
  //         setFinishedLoading(true);
  //       }
  //     else{
  //       setFinishedLoading(false); // is the end
  //     }
  //   }
  // }, [localWebResults,fetchResults,loadMore]);
  useEffect(() => {
    setIsResultsExpanded(false);
  }, [fetchResults])


  return (
    <GeneratedResultsContainer
        style={{
            paddingBottom: containerIsExpanded && '1px',
            marginBottom:
            containerIsExpanded && !isSmallScreen
                ? '15px'
                : containerIsExpanded && isSmallScreen && '8px',
            display:( !( fetchedResults && fetchedResults.length >0 )) && 'none',
            // backgroundColor: (question) ? '#fffff':'#fffff',
            // border:"",
            // backgroundo: (question) && '0.16',
        }}
    >
        {/* Comment/Uncomment */}
        {/* {fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && !isSmallScreen && ( */}
        <SuggestedAnswer
          p1
          regular
          textoffblack
          className="title"
          onClick={toggleAIResults}
          style={{
            cursor: answerProgress <=100? 'default' : 'pointer',
            // marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px'
          }}
        >
          { true &&(
              <>
              {(answerProgress>=100)? (<ExpandButton></ExpandButton>):(
                <div
                style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:'4px 6px 0 6px',
                      }}
                >
                <Loader visible={true} color="#8771DF"  height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
                </div>
              )}
              {' '}
              <Container>
            {containerIsExpanded ? (

              <Row>
                <p
                style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding:'0px 5px ',
                          fontStyle: 'italic',
                        }}>These responses are AI generated, without human review. Always exercise clinical judgment before using any provided information.</p>
              </Row>
            ):(            <Row>
              <div
              // className={!(props.hasBookmarks && props.hasBookmarks.length > 0) ? `card_body `+ props.result.expanded : (props.result.expanded) ? `card_body undefined` : `card_body expanded`}
              className={`card_body_generated_answer `}
              aria-expanded={containerIsExpanded ? true : false}
              >
              <LineClamp className={containerIsExpanded ? '':'truncate'}
                dangerouslySetInnerHTML={{ __html:responseText }} />
            </div>
            </Row>)}
          </Container>
              {/* <P
                className={!containerIsExpanded ? "suggestedQuery_unexpanded p-left":"suggestedQuery p-left" }
                p1
                regular
                textoffblack
              >
                {"AI generated response"}
              </P> */}
              </>
          )}
        </SuggestedAnswer>

        {/* AI ASSISTED RESULTS */}
        {containerIsExpanded && props.references && props.references[0] &&(
          <>
          <Container>
            <Row>
              <div
              // className={!(props.hasBookmarks && props.hasBookmarks.length > 0) ? `card_body `+ props.result.expanded : (props.result.expanded) ? `card_body undefined` : `card_body expanded`}
              className={`card_body_generated_answer `}
              aria-expanded={containerIsExpanded ? true : false}
              >
              <LineClamp className={containerIsExpanded ? 'green':'truncate'}
                dangerouslySetInnerHTML={{ __html:responseText }} />
            </div>
            </Row>
            <Hr />
            <Row className="justify-content-center p-1">
              {!refencesExpanded ?(
              <Label onClick={expandReferences}>
                 <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown> References <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown>
              </Label>):(
              <Label onClick={expandReferences}>
                <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp> References <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp>
              </Label>)
              }
            </Row>
            {refencesExpanded && props.references && props.references[0] && props.references[1] && props.references[2] &&(
              <>
              <Row className="px-1 py-2 ">
                <Col>
                  <UniversalCard
                  allPublishersNames ={props.allPublishersNames}
                  publishersSources ={props.publishersSources}
                  key={'universal_card' + 1 + props.allPublishersNames[0]}
                  i={0}
                  isSearchResult={true}
                  result={props.references[0]}
                  isOnlineViewUrlPdf={props.isOnlineViewUrlPdf[0]}
                  publisher={props.allPublishersNames[0]}
                  publisherSource={props.publishersSources[0]}
                  purifyHtml={props.purifyHtml}
                  purifiedHtml={props.purifyHtml[0]}
                  bookmarksFetched={props.bookmarksFetched}
                  bookmarkedFetched={props.bookmarksFetched}
                  resultHasBookmark = {props.resultHasBookmark}
                  hasBookmarks={props.resultHasBookmark[0]}
                  updateBookmarks={props.updateBookmarks}
                  setUpdateBookmarks={props.setUpdateBookmarks}
                  resultHasFavourite ={props.resultHasFavourite}
                  isFavourite={props.resultHasFavourite[0]}
                  favouriteRemove={props.favouriteRemove}
                  toBeRemove={props.favouriteRemove[0]}
                  resultFavouriteID={props.resultFavouriteID}
                  favouriteID={props.resultFavouriteID[0]}
                  isLoggedin={props.isLoggedin}
                  isMagicUser={props.isMagicUser}
                  updateFavourites={props.updateFavourites}
                  callbackResultExpanded={props.callbackResultExpanded}
                  handleLinkoutClick={props.handleLinkoutClick}
                ></UniversalCard>
                </Col>
              </Row>
              <Row className="px-1 py-2 ">
                <Col>
                  <UniversalCard
                  allPublishersNames ={props.allPublishersNames}
                  publishersSources ={props.publishersSources}
                  key={'universal_card' + 1 + props.allPublishersNames[1]}
                  i={1}
                  isSearchResult={true}
                  result={props.references[1]}
                  isOnlineViewUrlPdf={props.isOnlineViewUrlPdf[1]}
                  publisher={props.allPublishersNames[1]}
                  publisherSource={props.publishersSources[1]}
                  purifyHtml={props.purifyHtml}
                  purifiedHtml={props.purifyHtml[1]}
                  bookmarksFetched={props.bookmarksFetched}
                  bookmarkedFetched={props.bookmarksFetched}
                  resultHasBookmark = {props.resultHasBookmark}
                  hasBookmarks={props.resultHasBookmark[1]}
                  updateBookmarks={props.updateBookmarks}
                  setUpdateBookmarks={props.setUpdateBookmarks}
                  resultHasFavourite ={props.resultHasFavourite}
                  isFavourite={props.resultHasFavourite[1]}
                  favouriteRemove={props.favouriteRemove}
                  toBeRemove={props.favouriteRemove[1]}
                  resultFavouriteID={props.resultFavouriteID}
                  favouriteID={props.resultFavouriteID[1]}
                  isLoggedin={props.isLoggedin}
                  isMagicUser={props.isMagicUser}
                  updateFavourites={props.updateFavourites}
                  callbackResultExpanded={props.callbackResultExpanded}
                  handleLinkoutClick={props.handleLinkoutClick}
                ></UniversalCard>
                </Col>
              </Row>
              <Row className="px-1 py-2 ">
                <Col>
                  <UniversalCard
                  allPublishersNames ={props.allPublishersNames}
                  publishersSources ={props.publishersSources}
                  key={'universal_card' + 2 + props.allPublishersNames[2]}
                  i={2}
                  isSearchResult={true}
                  result={props.references[2]}
                  isOnlineViewUrlPdf={props.isOnlineViewUrlPdf[2]}
                  publisher={props.allPublishersNames[2]}
                  publisherSource={props.publishersSources[2]}
                  purifyHtml={props.purifyHtml}
                  purifiedHtml={props.purifyHtml[2]}
                  bookmarksFetched={props.bookmarksFetched}
                  bookmarkedFetched={props.bookmarksFetched}
                  resultHasBookmark = {props.resultHasBookmark}
                  hasBookmarks={props.resultHasBookmark[2]}
                  updateBookmarks={props.updateBookmarks}
                  setUpdateBookmarks={props.setUpdateBookmarks}
                  resultHasFavourite ={props.resultHasFavourite}
                  isFavourite={props.resultHasFavourite[2]}
                  favouriteRemove={props.favouriteRemove}
                  toBeRemove={props.favouriteRemove[2]}
                  resultFavouriteID={props.resultFavouriteID}
                  favouriteID={props.resultFavouriteID[2]}
                  isLoggedin={props.isLoggedin}
                  isMagicUser={props.isMagicUser}
                  updateFavourites={props.updateFavourites}
                  callbackResultExpanded={props.callbackResultExpanded}
                  handleLinkoutClick={props.handleLinkoutClick}
                ></UniversalCard>
                </Col>
              </Row>

              {/* <Row className='justify-content-center p-3'>
                  <Hr/>
              </Row> */}

              </>
            )}

          </Container>
          </>)}
    </GeneratedResultsContainer>
  );
};

export default GeneratedAnswer;
