import React, {useContext, useEffect, useState} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';

import { AppContext } from '../../../context';
import {
    SuggestedQuestion,
    MachinResultsContainer,
  } from '../styles'; // we use the style sheet from results cards
import { Button } from 'react-bootstrap';
import { P } from '../../theme/typography';

import ChatResultCards from '../ChatResultCards';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';

import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import { ColumnCalcsModule } from 'tabulator-tables';
import Loader from 'react-loader-spinner';


const SuggestedQuestionCcontainer = () => {
    const {
        fetchedResults,
        fetchedSuggestedQuestion,
        progress,
        machineResults,
        localWebResults,
        isResultsExpanded,
        setIsResultsExpanded,
        fetchResults,
        trackers,
        setActiveChatIndex,
        loadMore,
        setLoadMore,
        setQueueDone,
        clearLocalWebResults,
        setClearLocalWebResults,
        assistedAiAllowWebUsage,
        numMachineResults,
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const handleLoadMore = () => {
    setActiveChatIndex(0);
    setLoadMore(true);
    setQueueDone(false);
    setClearLocalWebResults(false);
  };
  const [question, setQuestion] = useState(false);
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);



  function toggleAIResults() {
    // zero results and loading dont open 
    // any results open 
    // zero result and finishedloading OPEN 
    if( ( machineResults && typeof Object.values(machineResults)[0] === 'undefined' )
        || ( machineResults && Object.values(machineResults)[0].length <= 0 && ! finishedLoading)
     ){
      // setContainerIsExpanded(false);
      console.log("click excluded nothing");
    }

    // no unexpanding if  fetchedSUggestedQuestion is not found
    else if ( typeof fetchedSuggestedQuestion !== 'undefined' && fetchedSuggestedQuestion.length > 0 
    && fetchedSuggestedQuestion[0]){
      setContainerIsExpanded(!containerIsExpanded);
    }

  }

  
  const ExpandButton =  props => {
    return(
      <Button
      size="sm"
      className="progress-button"
      style={{
        cursor: progress < 100 ? 'default' : 'pointer',
        backgroundColor: progress < 100 && '#a99ae8',
      }}
      >
      {containerIsExpanded  ?  (
        <FaChevronUp color="#fff"/>
      ) : (
        <FaChevronDown color="#fff" />
      )}
      </Button>
    );
  }

  useEffect(() => {
    if(containerIsExpanded !==  isResultsExpanded && typeof containerIsExpanded !== 'undefined' && typeof isResultsExpanded !== 'undefined'){
      setIsResultsExpanded(containerIsExpanded);
    }
  }, [containerIsExpanded])
  

  useEffect(() => {
    if(typeof fetchedSuggestedQuestion !== 'undefined' && fetchedSuggestedQuestion.length > 0 
      && fetchedSuggestedQuestion[0] ){
      setQuestion(true);
    }
    else{
      setQuestion(false);
    }
  }, [fetchedSuggestedQuestion,fetchResults,assistedAiAllowWebUsage])
  
  useEffect(() => {
    setContainerIsExpanded(false);
  }, [fetchedSuggestedQuestion]);
  
  // localWebResults as a source are only sent last if no other get-answer-from-results request provided a result
  // if we have localWebResults we are not sending anymore get-answer-from-results resquest
  useEffect(() => {
    if(localWebResults && Object.values(localWebResults)[0] && Object.values(localWebResults)[0].length){
      // if we have web result we have finish all the call to get-answer-from-result
      setFinishedLoading(true) ; 
    }
    else{
      // if()
      if(numMachineResults && numMachineResults[0] === 2 ){
        setFinishedLoading(true);
      }
      else if (!fetchResults[0] && loadMore === false && 
        !Object.values(localWebResults)[0].length ){
          setFinishedLoading(true);
        }
      else{
        setFinishedLoading(false); // is the end
      }
    }
  }, [localWebResults,fetchResults,loadMore])
  
 
  return (
    <MachinResultsContainer
        style={{
            paddingBottom: containerIsExpanded && '1px',
            marginBottom:
            containerIsExpanded && !isSmallScreen
                ? '15px'
                : containerIsExpanded && isSmallScreen && '8px',
            display:( !( fetchedResults && fetchedResults.length >0 )) && 'none',
            backgroundColor: (question) ? 'rgba(133, 113, 223, 0.16)':'rgba(251,251,253,1)',
            // backgroundo: (question) && '0.16',  
        }}
    >
        {/* Comment/Uncomment */}
        {/* {fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && !isSmallScreen && ( */}
        <SuggestedQuestion
          p1
          regular
          textoffblack
          className="title"
          onClick={toggleAIResults}
          style={{
            cursor: progress < 100 ? 'default' : 'pointer',
            marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px'
          }}
        >
          {fetchedSuggestedQuestion &&
          fetchedSuggestedQuestion.length > 0 &&(
              <>
              {(Object.values(machineResults)[0] && Object.values(machineResults)[0].length) || (finishedLoading)? (<ExpandButton></ExpandButton>):(
                <div
                style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:'4px 6px 0 6px',
                      }}
                >
                <Loader visible={true} color="#8771DF"  height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
                </div>  
              )}
              {' '}
              <P
                className={!containerIsExpanded ? "suggestedQuery_unexpanded p-left":"suggestedQuery p-left" }
                p1
                regular
                textoffblack
              >
                {fetchedSuggestedQuestion[0]}
              </P>
              </>
          )}
        </SuggestedQuestion>
        
        {/* AI ASSISTED RESULTS */}
        {containerIsExpanded &&
        !(
          !fetchResults[0] &&
          !assistedAiAllowWebUsage &&
          Object.values(machineResults)[0] &&
          !Object.values(machineResults)[0].length
        ) && (
          <>
          <ChatResultCards
              fetchedChatResults={Object.values(machineResults)[0]}
              isSearchResult={false}
              isOnSearchPage={true}
          />
          {!fetchResults[0] &&
              trackers[0] < 100 &&
              loadMore === false &&
              !Object.values(localWebResults)[0].length &&
              !clearLocalWebResults &&
              assistedAiAllowWebUsage && (
              <div
                  className="load-more-container"
                  style={{
                  marginTop: isSmallScreen ? '8px' : '1rem',
                  marginBottom: isSmallScreen ? '8px' : '1rem',
                  }}
              >
                  <span className="load-more" onClick={handleLoadMore}>
                  Load more
                  </span>
              </div>
              )}

          {fetchResults[0] &&
              !Object.values(localWebResults)[0].length &&
              loadMore !== false &&
              !clearLocalWebResults && (
              <LoadingIndicatorEllipsis></LoadingIndicatorEllipsis>
              )}
          </>
        )}
    </MachinResultsContainer>
  );
};

export default SuggestedQuestionCcontainer;
