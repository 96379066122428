import React, { useContext, useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context';

import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalPDFPreview = ({
  modalPDFShow,
  modalPDFClose,
  centered,
  result,
}) => {
  const { 
    userContext,
  } = useContext(
    AppContext
  );

  const [textareaValue, setTextareaValue] = useState('');

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    setTextareaValue('');
  }, [modalPDFShow]);

  const previewUrl = useMemo(
    () => {
      if (result.online_view_url) {
        return result.online_view_url.replace("__PUBLIC_TOKEN__", userContext.search_params);
      }
    }, [result.online_view_url]
  );

  return (
    <StyledModal
      show={modalPDFShow}
      onHide={() => modalPDFClose()}
      centered={centered}
      dialogClassName="modal-90h"
    >
      <Modal.Body>
        <div>
        </div>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalPDFClose()}
        />
        {typeof previewUrl === "string" && (previewUrl.endsWith('/'))  ?
        <iframe className="pdf-preview" type="application/pdf" src={previewUrl} sandbox='' ></iframe>:
        <iframe className="pdf-preview" type="application/pdf" src={previewUrl} ></iframe> 
      }
      </Modal.Body>
    </StyledModal>
  );
};

export default ModalPDFPreview;
