import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { getUserAvatarFullName, getUserAvatarExtraInfo, getUserAvatarOrgAlias, goToDashboard } from '../../functions/utils';
import { useLocation, useHistory } from 'react-router-dom';
import useDocumentScrollThrottled from '../../functions/useDocumentScrollThrottled';
import SearchBar from '../search-bar';
import Button from '../button';
import ToggleButton from '../toggle-button';

import { H1 } from '../../components/theme/typography';
import { ReactComponent as MedwiseLogoWithName } from '../../assets/svgs/medwise-logo-with-name.svg';
import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';

import { ReactComponent as ChevronIcon } from '../../assets/svgs/chevron.svg';
import Nav from './styles';
import LoadingIndicator from '../loading-indicator';
import { AppContext } from '../../context';
import { FaFirstdraft } from 'react-icons/fa';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';


const HeaderMobile = ({ withSearchBar, landingPageSearchBar,landingPage }) => {
  const [toggle, setToggle] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userData,setUserData] = useState(false);
  const [isAdminUser,setIsAdminUser] = useState(false);
  const [isMagicUser,setIsMagicUser] = useState(false);
  const [allowSignup,setAllowSignup] = useState(false);
  const [currentUserEmail,setCurrentUserEmail] =  useState(undefined);
  const [organisation, setOrganisation] = useState(undefined);
  const [organisationLogo, setOrganisationLogo] = useState(false);
  const [organisationUrlLogo, setOrganisationUrlLogo] = useState('');
  const [organisationColour, setOrganisationColour] = useState(false);
  const [organisationColourLeft, setOrganisationColourLeft] = useState(''); //useState('#fff');
  const [organisationColourRight, setOrganisationColourRight] =  useState('');//useState('#fff');
  const [organisationColourBar, setOrganisationColourBar] = useState(''); //useState('#8771df');
  const dashboardBarStyle = {
      "--bar-colour": organisationColourBar,
  };
  // const [showBookmarkBanner, setShowBookmarkBanner] = useState(false);
  // const [requestStopBanner, setRequestStopBanner] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [timeOutId, setTimeOutId]= useState(undefined);
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source(); 
  const {setSearchContext,searchContext,userStats,setUserStats,setRequestUser,userOrganisationContext,setUserOrganisationContext,removeUserOrganisationContext,userContext,setUserContext,setRequestTrackerContext,setUserId,setSkipRequests} = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  // Every time isLoggedin changes and at the 1st render, it controls whether the user is logged in or not and displays either account avatar or sign up & log in buttons
  // ! we may have prbl when users logs out with something other than logout button and when user is login and header does not re-render
  useEffect(() => {
    // let user_info =
    if (userContext && userContext['is_anonymous']) {
      setIsLoggedin(false);
      setIsMagicUser(false);
      setIsAdminUser(false);
    } else {
      if (userContext && userContext["email"]) {
        setIsLoggedin(true);
        if (userContext['org_name']) {
          let truncated_name =userContext['org_name'].substring(0,32);
          if (truncated_name.length !== userContext['org_name'].length ) {
            setOrganisation(userContext['org_name'].substring(0,32)+ '...');
          } else {
            setOrganisation(userContext['org_name']);
          };
        };

        if (userContext['org-user-type']==="admin") {
          setIsAdminUser(true);
          setIsMagicUser(false);
        };

        if (userContext['org-user-type']==="magic"){
          setIsMagicUser(true);
          if (userContext['magic-signup-allowed']===false) {
            setAllowSignup(false);
          } else {
            setAllowSignup(true);
          }
        }
      }
    }  
  },[userContext]);

  useEffect(()=>{
    if (userOrganisationContext && userOrganisationContext['logo_path']  && userOrganisationContext['logo_path'] !== null ) {
      if (userOrganisationContext['logo_path'] === 'name'){
        setOrganisationLogo(false);
      }
      else{
        setOrganisationLogo(true);
        setOrganisationUrlLogo(userOrganisationContext['logo_path']);
      }
    }
    if (!organisationColour && userOrganisationContext && userOrganisationContext['colours'] && (userOrganisationContext['colours']['left'] !== null || userOrganisationContext['colours']['right'] !== null)) {
      setOrganisationColour(true);
      if ((userOrganisationContext['colours']['left'] !== null) && (userOrganisationContext['colours']['left'] !== '')) {
        setOrganisationColourLeft(userOrganisationContext['colours']['left']);
      }
      if ((userOrganisationContext['colours']['right'] !== null) && (userOrganisationContext['colours']['right'] !== '')) {
        setOrganisationColourRight(userOrganisationContext['colours']['right']);
      }
    }
    if ( userOrganisationContext && userOrganisationContext['colours'] && userOrganisationContext['colours']['dashboard_bar'] && (userOrganisationContext['colours']['dashboard_bar'] !== null) && (userOrganisationContext['colours']['dashboard_bar'] !== '')) {
      setOrganisationColourBar(userOrganisationContext['colours']['dashboard_bar']);
    }
  },[userOrganisationContext]);

  // Header hide/show on scroll functionality is inspired by https://medium.com/mtholla/create-a-transitioning-header-using-react-hooks-like-mediums-c70ed211f7c9
  // Via custom function: functions/useDocumentScrollThrottled.js
  const [shouldHideHeader, setShouldHideHeader] = useState(false);

  const MINIMUM_SCROLL = 50;
  const TIMEOUT_DELAY = 100;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    let id= setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
    setTimeOutId(id);
  });

  const hiddenStyle = shouldHideHeader ? 'hidden' : '';

  // Lock the body scroll when the mobile menu is active
  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  },[toggle]);

  //clean timeOut 
  useEffect(() => () => {
    if (typeof timeOutId !== "undefined"){
      clearTimeout(timeOutId);
    }
  });

  //
  useEffect(() => () => {
    let tempUserData = userContext;
    if( tempUserData || userContext['email'] ){
      setUserData(true); 
    }
  },[currentUserEmail]);

  const handleLogout = () => {
    setUserContext({'email':'', 'org_name':undefined});
    setRequestTrackerContext(prev => { return {...prev, 'logout-user':true}});
    setSkipRequests(true);
    setUserId(99);// cancel and skip request   
    axios
      .get(`${window.BASE_URL_USER}logout-user`, {withCredentials: true})
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        if (res.data.success) {
          // removeUserSession();
          // setUserContext({email:''});
          setSearchContext({...searchContext,q:undefined});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          // setUserOrganisationContext(undefined);
          removeUserOrganisationContext();
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          setIsLoggedin(false);
          setOrganisation(undefined);
          setOrganisationLogo(false);
          setOrganisationColourLeft('#fff');
          setOrganisationColourRight('#fff');
          setOrganisationColourBar('#fff');
          setOrganisationUrlLogo('');
          history.push('/');
        } else {
          console.log(
            'The request is successful, but for some reason res.data.success is not received: \n',
            res
          );
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          // setUserOrganisationContext(undefined);
          removeUserOrganisationContext();
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          setIsLoggedin(false);
          setOrganisation(undefined);
          setOrganisationLogo(false);
          setOrganisationColourLeft('#fff');
          setOrganisationColourRight('#fff');
          setOrganisationColourBar('#fff');
          setOrganisationUrlLogo('');
          history.push('/');
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        setUserId(0);
        setSkipRequests(false);
        console.log(error);
      });
  };

  const handleSignup = () => {
    history.push('/signup');
  };

  const handleLogin = () => {
    history.push('/login');
  };

  const handleFeedback = () => {
    history.push("/dashboard/users_feedback.html");
  };

  // handle logout for magic user signup
  // we wait for user to be logout before redirrecting
  const handleLogoutmagicSignup = () => {
    history.push('/signup');
  };
  
  const handleLogoutmagicLogin = () => {
    history.push('/login');
  };

  const UserAccountDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="navigation_account--toggle1 d-md-block"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >

      {(isLoggedin && organisation && organisationUrlLogo && !withSearchBar) && (
        <span id="org_img">
          <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
        </span>
      )}
      {(isLoggedin && organisation && !organisationUrlLogo && !withSearchBar) && (
        <span id="org_name" className="text-center align-top">{organisation}</span>
      )}

      {(isLoggedin && organisation && organisationUrlLogo && withSearchBar) && (
        <span id="org_img_search_bar">
          <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
        </span>
      )}
      {(isLoggedin && organisation && !organisationUrlLogo && withSearchBar) && (
        <span id="org_name_search_bar_div">
          <span id="org_name_search_bar" className="text-center align-top">{organisation}</span>
        </span>
      )}

      {(isLoggedin && !withSearchBar) && (
        <div id="nav_account_info">
          {isMagicUser ? (
            <div id="nav_account_name">{getUserAvatarOrgAlias(userOrganisationContext)}</div>
          ) : (
            <div id="nav_account_name">{getUserAvatarFullName()}</div>
          )}
          {isAdminUser ? (
            <div id="nav_account_location">Admin</div>
          ) : (
            <div id="nav_account_location">{getUserAvatarExtraInfo()}</div>
          )}
        </div>
      )}

      {(isLoggedin && withSearchBar) && (
        <div id="nav_account_info_search_bar">
          {isMagicUser ? (
            <div id="nav_account_name">{getUserAvatarOrgAlias(userOrganisationContext)}</div>
          ) : (
            <div id="nav_account_name">{getUserAvatarFullName()}</div>
          )}
          {isAdminUser ? (
            <div id="nav_account_location">Admin</div>
          ) : (
            <div id="nav_account_location">{getUserAvatarExtraInfo()}</div>
          )}
        </div>
      )}
      
      <ChevronIcon id="nav_account_svg" />
    </div>
  ));

  const getDropDownMenu = () => {
    let menu ; 
    if (isMagicUser) { // option 8
      if (allowSignup) {
        menu =     
        <div className="navigation_links">
          {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
            <span className="red-dot"></span>
          )}
          <Dropdown className="navigation_account">
            <Dropdown.Toggle as={UserAccountDropdown}/>
            <Dropdown.Menu align="right">
              <Dropdown.Item onClick={()=> handleSignup()}>Sign up</Dropdown.Item>
              <Dropdown.Item onClick={()=> handleLogin()}>Log in</Dropdown.Item>
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"} rel="noopener noreferrer">Feedback
                {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                  <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
                )}
              </Dropdown.Item>
              <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
              <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleLogout()}>Medwise Std</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>;
      } else {
        menu =     
        <div className="navigation_links">
          {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
            <span className="red-dot"></span>
          )}  
          <Dropdown className="navigation_account">
            <Dropdown.Toggle as={UserAccountDropdown}/>
            <Dropdown.Menu align="right">
              <Dropdown.Item onClick={()=> handleLogin()}>Log in</Dropdown.Item>
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"} rel="noopener noreferrer">Feedback 
                {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                  <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
                )}
              </Dropdown.Item>
              <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
              <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleLogout()}>Medwise Std</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>;
      };
    } else{ // option 9
      menu =   
      <div className="navigation_links">            
        {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
          <span className="red-dot"></span>
        )}
        <Dropdown className="navigation_account">
          <Dropdown.Toggle as={UserAccountDropdown}/>
          <Dropdown.Menu align="right">
            <Dropdown.Item href="/account">Account</Dropdown.Item>

            {isAdminUser &&(
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/org_summary.html"}> Dashboard </Dropdown.Item>
            )}
            {!isAdminUser &&(
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/index.html"}> Dashboard </Dropdown.Item>
            )}

            <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"}> Feedback 
              {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
              )}
            </Dropdown.Item>

            <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
            
            <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>

            <Dropdown.Item onClick={()=>handleLogout()}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>;
    }


  return menu;
  }; 

  const goToLanding = () =>{
    history.push('/');
  };

  return (
    <>            
    <Container fluid>
      <Row>
        <Nav
        className={organisation || withSearchBar ?" ":" withBorder" } style={{backgroundImage: (isLoggedin && organisationColour) && `linear-gradient(to right, ${organisationColourLeft}, ${organisationColourRight})` , top: landingPage?'24px':'0px'}}
        withSearchBar={withSearchBar ? withSearchBar : undefined} 
        >
        <div className="navigation_logoAndToggle">
          <a href={window.BASE_URL_DASHBOARD} onClick={goToLanding} className='logoWithName'>
            <MedwiseLogoWithName/>
          </a>
          <a href={window.BASE_URL_DASHBOARD} onClick={goToLanding}  className='logoWithoutName'>
            <MedwiseLogo/>
          </a>
          <Row >
            <Col className='loaderContainter' ><LoadingIndicator/></Col>
          </Row>
        </div>
        <div className="navigation_toggle" > 
          <ToggleButton toggle={toggle} setToggle={setToggle} />
        </div>
      { !toggle && organisation && organisationLogo  &&  (
        <Container style={{zIndex:-1}}>
        <Row>
          <Col md={{ span: 8, offset: 3 }} >
            <div className={"navigation_org_logo_mobile"} >
            <img
              src={organisationUrlLogo}
              srcSet={organisationUrlLogo}
              alt= {organisation +"logo"}  
              width={'115px'}
              height={'56px'} 
             />
            </div>       
          </Col>
        </Row>
      </Container>
        )}
      {!toggle && organisation && !organisationLogo  &&  (
        <Container style={{zIndex:-1}}>
        <Row>
          <Col md={{ span: 8, offset: 3 }} >
          <div className={"navigation_org_logo_mobile"} >
          <H1 h1 regular textoffblack>{organisation}</H1>
          </div>       
          </Col>
        </Row>
      </Container>   
        )}
      {/* the whole right menu is bellow */}
      {( toggle) &&(
        <>
        {toggle &&(
          <div className="navigation_mobileMenuOverlay"></div>)}
          {!isLoggedin && withSearchBar && !isSmallScreen && !toggle && ( // option 1
              <div className="navigation_links login_button_intermediary_screens">
                <ul>
                  <li className="">
                    <Button
                        type="link"
                        colorsecondary
                        buttonsmall
                        to="/login"
                        text="Log in"
                        width="96px"
                        onClick={() => {  
                            if(isSmallScreen){
                              setToggle(!toggle);
                            } }}
                    />
                  </li>

                  <li id="anony-sign-up">
                    <Button
                      type="link"
                      colorprimary
                      buttonsmall
                      to="/signup"
                      text="Sign up"
                      width="96px"
                      onClick={() => {  
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>
                </ul>
              </div>
          )}
          <div
              //  className="navigation_links"
               className={(toggle ? 'navigation_links_toggle' : 'navigation_links') }>
            <ul>
               {/*
               Show login and sign up  
               with account buttons from different products (no longer it use) and is not logged in */}
              {  !isLoggedin && ( // option 2
              // { && (!isLoggedin || isMagicUser ) && (
                <>
                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      to="/login"
                      text="Log in"
                      width="96px"
                      onClick={() => {  
                            if(isSmallScreen){
                              setToggle(!toggle);
                            } }}
                    />
                  </li>
                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      to="/signup"
                      text="Sign up"
                      width="96px"
                      onClick={() => {  
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>
                </>
              )}
              {/*
                Show logout &&  account
                Below block outputs "Account" and "Log out" buttons on mobile. (Account button is ignored if user is already on the "/account" route) */}
              {/* Note that: There isn't "Avatar" on mobile */}
              {/* {isLoggedin && isSmallScreen && ( */}
              {isLoggedin && (isSmallScreen) && !isMagicUser && (
                <>
                  {/* show acount  if not in account and not in magicuser */}
                  {location.pathname !== '/account' && ( // option 3
                    <li>
                      <Button
                        type="link"
                        colorsecondary
                        buttonsmall
                        to="/account"
                        text="Account"
                        width="152px"
                        onClick={() => setToggle(!toggle)}
                      />
                    </li>
                  )}

                    <li>
                    <Button
                        type="external_link"
                        colorprimary
                        buttonsmall
                        text="Support"
                        width="90px"
                        to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" 
                        rel="noopener noreferrer" 
                        target="_blank"
                      />
                    </li>

                    {/* we used a different About us button for mobile loggedin and  not login */}
                    <li>
                      <Button
                        type="link"
                        colorsecondary
                        buttonsmall
                        text="About us"
                        width="90px"
                        to= 'http://about.medwise.ai/'
                        target="_blank"
                      />
                    </li>

                    <li>
                      <Button
                        type="link"
                        colorprimary
                        buttonsmall
                        to="#"
                        text="Log out"
                        width="152px"
                        onClick={() => handleLogout()}
                      />
                    </li>
                </>
              )}

              { (isSmallScreen)  && !isLoggedin && !isMagicUser &&( // option 6
                <li>
                  <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      text="About us"
                      width="90px"
                      to= 'http://about.medwise.ai/'

                  />
                </li>
              )}
              {/* TODO:  Refactoring */}
              {/* show organisation name without searchbar when it is next to dropdown menu */}
                {/* {!isSmallScreen && organisation && 
                  isLoggedin &&  !withSearchBar &&
                  organisationUrlLogo==='' && !isMagicUser&& (
                <li className='organisationName'>
                    <Label >
                      {organisation}
                    </Label>
                </li>
                )} */}
                {/* show organisation name without searchbar when it is next to dropdown menu */}
                {/* {!isSmallScreen && organisation && 
                  isLoggedin &&  !withSearchBar &&
                  organisationUrlLogo==='' && isMagicUser && (
                <li className='organisationNameMagicUser'>
                    <Label >
                      {organisation}
                    </Label>
                </li>
                )} */}

                {/* { organisationLogo  && organisation&& !isSmallScreen && 
                  isLoggedin  && !isMagicUser  && !withSearchBar &&(
                  <li className='organisationLogo'>
                  <img
                    src={organisationUrlLogo}
                    srcSet={organisationUrlLogo}
                    //  xmlns={organisationUrlLogo}
                     alt= {organisation +"logo"}
                     width={'115px'}
                     height={'56px'} /> 
                  </li>
                )} */}
                 {/* { organisationLogo  && organisation && !isSmallScreen && 
                  isLoggedin && isMagicUser && !withSearchBar &&(
                  <li className='organisationLogoMagic'>
                    <img
                      src={organisationUrlLogo}
                      srcSet={organisationUrlLogo}
                      //  xmlns={organisationUrlLogo}
                      alt= {organisation +"logo"}
                      width={'115px'}
                      height={'56px'} /> 
                  </li>
                )} */}
                {/* show organisation name with searchbar 
                  when url has not being loaded we get  orgUrlLogo=''
                  if it has been set we have orgUrlLogo != '' 
                */}
                {/* { !isSmallScreen && organisation  && withSearchBar &&
                  isLoggedin && !isMagicUser &&  (
                <li className='organisationNameWithSearchBar'>
                    <Label>{organisation}</Label>
                </li>)}
                { !isSmallScreen && organisation  && withSearchBar &&
                  isLoggedin && isMagicUser && (
                <li className='organisationNameWithSearchBarMagicUser'>
                    <Label>{organisation}</Label>
                </li>)} */}
                {/* show organisation name with searchbar */}
                {/* {!isSmallScreen && organisationLogo
                  && withSearchBar && isLoggedin && !isMagicUser &&(
                  <li className='organisationLogoWithSearchBar'>
                    <img
                     src={organisationUrlLogo}
                     srcSet={organisationUrlLogo}
                     alt= {organisation +"logo"}  
                     width={'115px'}
                     height={'56px'}/> 
                </li>)}
                {!isSmallScreen && organisationLogo
                  && withSearchBar && isLoggedin && isMagicUser &&(
                  <li className='organisationLogoWithSearchBarMagicUser'>
                    <img
                     src={organisationUrlLogo}
                     srcSet={organisationUrlLogo}
                     alt= {organisation +"logo"}  
                     width={'115px'}
                     height={'56px'}/> 
                </li>)} */}

              {/* show signup and logout for magic user  */}
              {/* { && isLoggedin  && toggle &&  (isMagicUser || !isSmallScreen) &&  ( */}
              {isLoggedin  && toggle && isMagicUser &&  ( // option 7
                <>
                  {allowSignup && (
                    <li>
                      <Button
                        type="link"
                        className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                        colorsecondary
                        buttonsmall
                        to="#"
                        text="Sign up"
                        width="96px"
                        onClick={()=>handleLogoutmagicSignup()}
                      />
                    </li>
                  )}

                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorprimary={(allowSignup ? 1 : 0) }
                      colorsecondary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      to="#"
                      text="Log in"
                      width="96px"
                      onClick={()=>handleLogoutmagicLogin()}
                    />
                  </li>
                  
                  <li>
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorsecondary={(allowSignup ? 1 : 0) }
                      colorprimary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce"
                      text="Support"
                      width="96px"
                      rel="noopener noreferrer" 
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary={(allowSignup ? 1 : 0) }
                      colorsecondary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      text="About us"
                      width="96px"
                      to='http://about.medwise.ai/'
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary={(allowSignup ? 1 : 0) }
                      colorprimary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      text="Log out"
                      width="96px"
                      to="#"
                      onClick={() => handleLogout()}
                      />
                  </li>
                </>
              )}
              {/* logged-in users hamburger menu */}
              {isLoggedin  && toggle && !isMagicUser && !isSmallScreen &&  ( // option 5
                <>
                  {/* show acount  if not in account and not in magicuser */}
                  {location.pathname !== '/account' && (
                    <li>
                      <Button
                        type="link"
                        className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                        colorsecondary
                        buttonsmall
                        to="/account"
                        text="Account"
                        width="96px"
                        onClick={() => setToggle(!toggle)}
                      />
                    </li>
                  )}

                  <li>
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce"
                      text="Support"
                      width="96px"
                      rel="noopener noreferrer" 
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      text="About us"
                      width="96px"
                      to='http://about.medwise.ai/'
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      text="Log out"
                      width="96px"
                      to="#"
                      onClick={() => handleLogout()}
                      />
                  </li>
                </>
              )}
            </ul>
          </div>
          {/* Below block outputs "User avatar", "Account" and "Log out" dropdowns on desktop. (Account option is ignored if user is already on the "/account" route) */}
          {/* Note that: "Avatar" is rendered by UserAccountDropdown component */}
          {isLoggedin && !isSmallScreen && (
              getDropDownMenu()            
          )}
        </>
      )}
    </Nav>
      </Row>
      {withSearchBar && !toggle && (
      <Row>
        <Nav
          className={"secondRow"+hiddenStyle} style={{backgroundImage: (isLoggedin && organisationColour) && `linear-gradient(to right, ${organisationColourLeft}, ${organisationColourRight})`}}
          withSearchBar={withSearchBar ? withSearchBar : undefined}  
        >
            <Container fluid>
              <Row>
                <Col md={{ span: 12 }} className='p-1' >
                  <SearchBar isAtHeader={true} />
                </Col>
              </Row>
            </Container>
        </Nav>
    </Row>
     )}
    </Container>

    </>
  );
};

export default HeaderMobile;
