import  React,{ useRef, useEffect, useState } from "react";
import ContainerSuggest from  "./styles.js";
import "./styles.css";

import AsyncSelect from 'react-select/async';
import useScript from "../../useScript/useScript.js";

const GoogleSuggest = (props) => {
 const url = useScript("https://maps.googleapis.com/maps/api/js?key=", "&libraries=places");


//  const [input ,setInput] = useState('');
 const autoCompleteRef = useRef();

 const inputRef = useRef();
 const [triggerInput ,setTriggerInput] = useState(undefined);
 const [googlePredictions ,setGooglePredictions] = useState(undefined);
 const [focused, setFocused] = useState(false);
 const [selectedInput ,setSelectedInput] = useState(undefined);
 const [timeOutId ,setTimeOutId] = useState(undefined);
 const [cancelTimeOut ,setCancelTimeOut] = useState(false);
 const [waitingForGetPlace ,setWaitingGetPlace] = useState(false);





 const options = {
  fields: ["address_components","geometry","adr_address", 'formatted_address',"formatted_phone_number",
  "icon","international_phone_number", "name",'place_id',"plus_code","rating","types","url","user_ratings_total","vicinity","website"],
  // types: ["health","local_government_office","hospital","drugstore","doctor"], // ,"local_government_office"

 }; 


 useEffect(() => {
    if(window.google && window.google.maps && url && typeof autoCompleteRef.current ==="undefined" ){
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
           );
        autoCompleteRef.current.addListener("place_changed", async function () {
          setCancelTimeOut(true);
          props.setBlockGetVersion(true);
          // console.log('48 place',autoCompleteRef.current);
          const place = await autoCompleteRef.current.getPlace();
          // setUserInput(place.name);
          // console.log('place',place);
          // console.log('cancel timeouit true');
          if (place.reviews){
            delete place.reviews;}
          if(place.photos){
            delete place.photos;}
          // setSelectedInput(place);
          props.setLocation(inputRef.current.value)
          props.setPlace(place);
          props.setBlockGetVersion(false);
        });
    }
 }, [url]);


 const greyNext = (e) => {
  // setUserInput(e.target.value);
  props.setPlace(undefined);
  // incase of user no change input 
  if(e.target.value !== props.location){
    props.setBlockNext(true);
  }
 };




 
//  str1.startsWith('sat')
// use start with name
 const handleOnBlur = (e) => {
  // console.log('ONBLUR inputRef.current.value',inputRef.current.value);

  setFocused(false);
  if(inputRef.current.value!== props.location && inputRef.current.value !=='' ){
    // when click an option , onblur is active before getPlace details and before inputRef
    // console.log('ONBLUR inputRef.current.value',inputRef.current.value);
    props.setBlockGetVersion(true);
    props.setLocation(inputRef.current.value);
    let id = setTimeout(() => {
          // this is cancel by getPlace detail 
          // if it is not cancel in 1000ms it means user did not click on options
          //then we can trigger get version with inputRef value
          // console.log('95');
          // props.setPlace(undefined); 
          props.setBlockGetVersion(false);
        }, 500);
    setTimeOutId(id);
  }
};


const handleOnFocus = (e) => {
  setFocused(true);
  props.setBlockGetVersion(true);
};


useEffect(() => {

  if(cancelTimeOut){
    props.setLoadingIcb(true);
    clearTimeout(timeOutId);
  }
}, [cancelTimeOut]);


 useEffect(() => {
  let id;
  // if google Autosuggest is from account page there is no need to trigger the focus 
  // the focus is force to trigger  thw widget suggestions
  if( document.getElementById("googleAutosuggest") && document.getElementById("googleAutosuggest") !== null && !props.fromAccountPage){
   id =  setTimeout(() => {
    if( document.getElementById("googleAutosuggest") && document.getElementById("googleAutosuggest") !== null && !props.fromAccountPage){
      document.getElementById("googleAutosuggest").focus();}
    }, "500");
}
 },[]);


 



 return (
  <>
  <ContainerSuggest >
   <input id='googleAutosuggest' ref={inputRef} defaultValue={props.location} onFocus={handleOnFocus} onBlur={handleOnBlur} onChange={(e)=>{greyNext(e)}} />
  </ContainerSuggest>
  </>
 );
};
export default GoogleSuggest;
// export default OrgFieldAutosuggest;
