import React  from 'react';

import OverideCard from '../override-card/index.js';
import ResultCard from '../result-card/index.js';
import WebCard from '../web-card/index.js';

const UniversalCard = (props) => {
  return (
    <>
      {props.result['from'] &&
      props.result['requested_obj'] &&
      props.result['from'] === 'private' &&
      props.result['requested_obj'] === 'override' ? (
      <OverideCard
        key={
          'override_' +
          props.i +
          '_' +
          props.allPublishersNames[props.i] 
          +'references'
        }
        i={props.i}
        isSearchResult={true}
        result={props.result}
        publisher={props.allPublishersNames[props.i]}
        publisherSource={props.publishersSources[props.i]}
        bookmarkedFetched={props.bookmarksFetched}
        hasBookmarks={props.resultHasBookmark[props.i]}
        updateBookmarks={props.updateBookmarks}
        setUpdateBookmarks={props.setUpdateBookmarks}
        isFavourite={props.resultHasFavourite[props.i]}
        toBeRemove={props.favouriteRemove[props.i]}
        favouriteID={props.resultFavouriteID[props.i]}
        isLoggedin={props.isLoggedin}
        isMagicUser={props.isMagicUser}
        updateFavourites={props.updateFavourites}
        callbackResultExpanded={props.callbackResultExpanded}
      ></OverideCard>
    ) 
    : props.result['from'] &&
      !props.result['requested_obj'] &&
      !props.result['online_pdf_url'] &&
      props.result['from'] === 'reranker' &&
      props.result['doc_publisher'] === 'Web' ? (
      <WebCard
        key={'web_references' + props.i + props.allPublishersNames[props.i]}
        i={props.i}
        isSearchResult={true}
        result={props.result}
        isOnlineViewUrlPdf={props.isOnlineViewUrlPdf[props.i]}
        publisher={props.allPublishersNames[props.i]}
        publisherSource={props.publishersSources[props.i]}
        purifiedHtml={props.purifyHtml[props.i]}
        bookmarkedFetched={props.bookmarksFetched}
        hasBookmarks={props.resultHasBookmark[props.i]}
        updateBookmarks={props.updateBookmarks}
        setUpdateBookmarks={props.setUpdateBookmarks}
        isFavourite={props.resultHasFavourite[props.i]}
        toBeRemove={props.favouriteRemove[props.i]}
        favouriteID={props.resultFavouriteID[props.i]}
        isLoggedin={props.isLoggedin}
        isMagicUser={props.isMagicUser}
        updateFavourites={props.updateFavourites}
        callbackResultExpanded={props.callbackResultExpanded}
        handleLinkoutClick={props.handleLinkoutClick}
      ></WebCard>
    ) : (
      <ResultCard
        key={'result_references' + props.i + props.allPublishersNames[props.i]}
        i={props.i}
        isSearchResult={true}
        result={props.result}
        isOnlineViewUrlPdf={props.isOnlineViewUrlPdf[props.i]}
        publisher={props.allPublishersNames[props.i]}
        publisherSource={props.publishersSources[props.i]}
        purifiedHtml={props.purifyHtml[props.i]}
        bookmarkedFetched={props.bookmarksFetched}
        hasBookmarks={props.resultHasBookmark[props.i]}
        updateBookmarks={props.updateBookmarks}
        setUpdateBookmarks={props.setUpdateBookmarks}
        isFavourite={props.resultHasFavourite[props.i]}
        toBeRemove={props.favouriteRemove[props.i]}
        favouriteID={props.resultFavouriteID[props.i]}
        isLoggedin={props.isLoggedin}
        isMagicUser={props.isMagicUser}
        updateFavourites={props.updateFavourites}
        callbackResultExpanded={props.callbackResultExpanded}
        handleLinkoutClick={props.handleLinkoutClick}
      ></ResultCard>
    )
  }
    </>
  );
};

export default UniversalCard;
