import React from 'react';
import Autosuggest from 'react-autosuggest';
// import { defaultTheme } from 'react-autosuggest/dist/theme';
import axios from 'axios';
import ContainerSuggest from "./styles";
import { GoogleMap, Marker,Autocomplete, useLoadScript } from "@react-google-maps/api";

const options = {
  fields: ["address_components", "geometry", "icon", "name"],
  types: ["health","university","hospital","drugstore","doctor"] 
 };

const renderInputComponent = (inputProps) => (
  <div>
    <input {...inputProps}  className={inputProps.isLoading ? 'loading' : ''} style={{borderColor: inputProps.isError ? '#EB5757' : '#E2E5ED'}}/>
  </div>
);

// const placesLibrary = ['places']; 

// const { isLoaded } =  useLoadScript({
//   googleMapsApiKey: "AIzaSyA-S4tt5-ACeH96XUW4XdNc6ccO29H93Fc",
//   libraries: placesLibrary
// });




// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value) => {
  return value ;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => {
  
  return suggestion ;
};
const otherValue = [{
  'full_name_display': "No results found . Select anyway",
  'address_display': ' ', 
  "postcode_display": '', 
  'full_name': 'Other',
  'address': 'Other', 
  "postcode": 'other'
}];
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <>
    <div className='address'>
      <div style={{'fontSize': '14px', 'maxHeight': '52px', 'color': '#212452'}} >{suggestion}
      </div>

        {/* <> */}
          {/* <div style={{ 'fontSize': '14px', 'maxHeight': '112px', 'color': ' #212452' }} >{suggestion}</div> */}
          {/* <div style={{ 'fontSize': '14px',  'height': '26px', 'color': ' #212452' }} dangerouslySetInnerHTML={{ __html:'<b>ODS Code: </b>' + suggestion['ods_code_display'] }}></div> */}
        {/* </>   */}
    </div>
  </>
);

class LocationSuggest extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      defaultValue:'',
      suggestions: [],
      isLoading:false,
    };
    this.state.value= this.props.location;
    this.previousValue = null;

    this.controller = new AbortController();

    this.CancelToken = axios.CancelToken;
    this.prev_source = this.CancelToken.source();
  }

  loadSuggestions (val){
      // Cancel the previous request
    if (this.previousValue ){
      this.controller.abort();
      this.prev_source.cancel('new request started');
      this.CancelToken = axios.CancelToken;
      this.prev_source = this.CancelToken.source();
      // this.setState({
      //   isLoading: false,
      // });
    }
    this.setState({
      isLoading: true,
      //suggestion['full_name_display']
      // suggestions:[]
      
    });
    const storeSuggestions = function (predictions, status) {
      console.log('111');
    };
    // request
    const service2 = new window.google.maps.places.AutocompleteService();
    let temp = service2.getPlacePredictions({input: val,fields: ["address_components", "geometry", "icon", "name"],
      types: ["health","university","hospital","drugstore","doctor"]}, storeSuggestions)
      .then((res) => {
        if (res.predictions) {
          let tempRes = res.predictions.map((suggestion) => {
            return suggestion.description} 
          );
          // previousValue = newSuggestions;
          // newSuggestions = tempRes;
          // let newSuggestions = otherValue.concat(res.predictions) ; 
          let newSuggestions = tempRes.concat([val]) ; 

          if (val.toLowerCase() === "other" || val.toLowerCase() === "othe" || val.toLowerCase() === "oth") {
            this.setState({
              isLoading: false,
              suggestions: newSuggestions,
              // suggestions: res.predictions.unshif(otherValue),
            });
          } else  {
            this.setState({
              isLoading: false,
              suggestions: newSuggestions,
              // suggestions:res.predictions.concat(otherValue),
            });
          }
          return tempRes;
        }
        else {
          // previousValue = newSuggestions;
          // newSuggestions = [];
          this.setState({
            isLoading: false,
            suggestions:[].concat([val]),
          });
          return [].concat([val])
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
          } 
        else{
          console.log("could not load suggestion",error);
          this.setState({
            isLoading: false,
            suggestions:[].concat([val]),
          });
        }

      });
  }


  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      // isLoading: true,
    });
    this.props.setLocation( newValue);
    this.props.setPostalCode( undefined );
    this.props.setOdsCode(undefined );
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value, reason }) => {
    this.loadSuggestions(value);
    // let current_source = CancelToken.source();
    // this.setState({
    //   suggestions: getSuggestions(value)
    // });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>{
    this.props.setLocation(suggestion);
    this.props.setPostalCode( suggestion);
    this.props.setOdsCode(suggestion);
    // if (this.props.ccgCallback){
    //   //'full_name']+ ', '+suggestion["postcode"]  +', '+suggestion["ods_code"]\
    //   let data =  {
    //     'full_name': suggestion['full_name'],
    //     'postcode': suggestion['postcode'],
    //     'ods_code': suggestion['ods_code']
    //  };
    //  if(this.props.ccgCallback(data)){
    //   this.props.ccgCallback(data);
    //  }
    // }
  };

  onSuggestionHighlighted= ({suggestion}) => {

  };

  render() {
    const { value, suggestions,isLoading } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type to search and select location...',
      value,
      isLoading,
      onChange: this.onChange,
      isError: this.props.isError
    };

    // Finally, render it!
    return (
      <ContainerSuggest>
        <Autosuggest
          // key={'userLocationsuggest'+prop}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected = {this.onSuggestionSelected}
          onSuggestionHighlighted = {this.onSuggestionHighlighted}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
        />
      </ContainerSuggest>
    );
  }
}
export default LocationSuggest;
